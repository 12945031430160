import { useEffect, useState } from 'react'
import {
    Row, Card, CardBody, CardHeader, CardText, Button, Modal, ModalHeader, ModalBody, Spinner, Table, UncontrolledAlert, ButtonGroup,
    Form, FormGroup, Label, Input
} from 'reactstrap';
import { ProductStatus, useAllProductsQuery, useMyProductsQuery, useRequestDeliveryMutation } from '../../redux/api/productApi';
import { useSsoLoginMutation, useGetActiveAccountsMutation } from '../../redux/api/ayrshareApi';
import { useAppDispatch } from '../../redux/hooks';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { api } from '../../redux/api/api';
import { BusyButton } from '../../components/Buttons/busyButton';
import { SocialAuditProductName } from '../../components/Products/socialAuditProductName';
import checkMark from '../../images/Eo_circle_green_checkmark.svg';
import reload from '../../images/Reload-icon.svg';
import redX from '../../images/Red_X.svg';

type SocialAuditConnectedStatusArgs = {
    isConnected: boolean | undefined;
    isRefreshRequired: boolean;
    isRefreshing: boolean;
    onRefreshClicked: () => void;
}

type FilteredProduct = {
    socialPlatformId: string,
    sku: string,
    id: string,
    isActive: boolean | undefined;
}


export const SocialAuditSubmitForReviewView = () => {
    const { data: allProducts, isLoading: allProductsLoading, isError: allProductsInError } = useAllProductsQuery();
    const { data: myProducts, isLoading: myProductsLoading, isError: myProductsInError } = useMyProductsQuery();
    const [getActiveAccounts, { data: activeSocialProfiles, isLoading: activeSocialProfilesLoading, isError: activeSocialProfilesInError, isSuccess: activeSocialProfilesLoaded }] = useGetActiveAccountsMutation();
    const[requestDelivery, { isLoading: isRequestDeliveryLoading, isSuccess: isRequestDeliverySuccess, isError: isRequestDeliveryError }] = useRequestDeliveryMutation();
    const [login, { isLoading: isSsoLoading, isSuccess: isSsoSuccess, isError: isSsoError, data: ssoUrl }] = useSsoLoginMutation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [nextBusy, setNextBusy] = useState<boolean>(false);
    const [nextError, setNextError] = useState<string | undefined>(undefined);
    const [filteredProducts, setFilteredProducts] = useState<(FilteredProduct | null)[] | undefined>(undefined);
    const [ytAccountName, setYtAccountName] = useState<string>("");
    const [connectStep, setConnectStep] = useState<number>(0);
    const [refreshNeeded, setRefreshNeeded] = useState<boolean>(false);
    const location = useLocation();
    const selectedSkus = location.state?.skus ?? []

    useEffect(() => { getActiveAccounts(); }, []);

    useEffect(() => {
        if (!allProducts || !myProducts ) {
            setFilteredProducts(undefined);
            return;
        }

        let m = myProducts.productGroups.find(g => g.name === "Social Audit")?.products.filter(p => p.status === ProductStatus.Active);
        let a = allProducts?.productGroups.find(g => g.name === "Social Audit")?.products
            .map(p => {
                let myProduct = m?.find(pp => pp.sku === p.sku);
                if (!myProduct) return null;
                let isActive = (!activeSocialProfiles || !activeSocialProfilesLoaded ) ? undefined
                    : activeSocialProfiles?.accountIds && activeSocialProfiles.accountIds.includes(p.socialPlatformId);
                return {
                    socialPlatformId: p.socialPlatformId,
                    sku: p.sku,
                    id: myProduct.paymentId,
                    isActive
                } as FilteredProduct
            }).filter(p => p && selectedSkus.includes(p.sku));
        setFilteredProducts(a);
    }, [selectedSkus, allProducts, myProducts, activeSocialProfiles, activeSocialProfilesLoaded])

    if (filteredProducts && filteredProducts.some(p => p?.isActive === false)) {
        return (<Navigate to="/products/socialAudit/connect" state={{skus: selectedSkus}} replace />);
    }

    const requestReport = async () => {
        requestDelivery({ products: filteredProducts as FilteredProduct[] });
    }

    return (
        <>
            <Row>
                <Card>
                    <CardHeader tag="h3">Social Media Audit: Submit For Review</CardHeader>
                    <CardBody>
                        <CardText>
                            <h4>Step 2. Submit request for review</h4>
                            When you are satisfied you have connected all your accounts, click the Request Report
                            button to submit your details for review
                        </CardText>

                        {(allProductsInError || myProductsInError || activeSocialProfilesInError) &&
                            <Row><UncontrolledAlert color="danger">There was an unexpected error obtaining information about this product</UncontrolledAlert></Row>}
                        {isRequestDeliveryError &&
                            <CardText><UncontrolledAlert color="danger">There was a problem trying to request your report. Please try again later.</UncontrolledAlert></CardText>}
                        {isRequestDeliverySuccess &&
                            <CardText><UncontrolledAlert>Your request for a Social Media Audit has been submitted.</UncontrolledAlert></CardText>}
                        {!isRequestDeliverySuccess &&
                            <BusyButton onClick={requestReport} label="Request Review" isBusy={isRequestDeliveryLoading} />}
                        {isRequestDeliverySuccess &&
                            <BusyButton onClick={() => navigate("/")} label="Back to Dashboard" isBusy={false} />}
                    </CardBody>
                </Card>
            </Row>
            
            <Row>
             </Row>

            
        </>);
}

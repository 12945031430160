import React, { useEffect, useState } from 'react';
import { BusyButton } from '../Buttons/busyButton';
import { selectIsMfaRequired, selectLoginState, selectTemporaryToken } from '../../redux/loginSlice';
import { Alert, Container, Row, Form, FormGroup, Label, Input, FormFeedback, Button, Spinner, FormText, UncontrolledAlert } from 'reactstrap';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { useGetMfaMethodsQuery, useInitiateMfaMutation, MfaMethods, useValidateMfaMutation } from '../../redux/api/loginApi';

const InitiateMfa = () => {
    const isLoggedIn = useAppSelector(selectLoginState);
    const isMfaRequired = useAppSelector(selectIsMfaRequired);
    const [code, setCode] = useState<string>("");
    const { data: mfaMethods } = useGetMfaMethodsQuery();
    const [selectedMethod, setSelectedMethod] = useState<{methodId: string, providerId: string} | undefined>(undefined);
    const [
        initiate,
        { data: initiateResult, isLoading: isInitiateLoading, isError: isInitiateInError },
    ] = useInitiateMfaMutation();
    const [validate, { isLoading: isValidateLoading, isError: isValidationInError, status: validationStatusCode }] = useValidateMfaMutation();
    const [displayInitiate, setDisplayInitiate] = useState<boolean>(true);

    // If MFA is not required, hide this component 
    if (!isMfaRequired) {
        return (null);
    }

    if (!mfaMethods) {
        return (<Spinner/>)
    }

    // If the temporary token is not obtained, then you should provide a view to get it again 
    if (!initiateResult?.token || displayInitiate) {
        return (
            <Form>
                <FormGroup>
                    <Alert>Please choose where you would like a verification code sent</Alert>
                </FormGroup>
                {Object.keys(mfaMethods.methods).map(key =>
                    <FormGroup key={key}>
                        <legend>{key}</legend>
                        {mfaMethods.methods[key].map(method =>
                            <FormGroup key={method.id} check>
                                <Input
                                    name="mfaMethods"
                                    type="radio"
                                    onClick={() => setSelectedMethod({ providerId: key, methodId: method.id})}
                                    checked={selectedMethod?.providerId === key && selectedMethod?.methodId === method.id}
                                />
                                {' '}
                                <Label check>
                                    {method.description}
                                </Label>
                            </FormGroup>
                        )}
                        {isInitiateInError && <FormGroup><UncontrolledAlert color="danger">There was a problem sending the verification code. Please try again.</UncontrolledAlert></FormGroup>}
                        <BusyButton isBusy={isInitiateLoading} disabled={selectedMethod == null} label="Submit" onClick={() => { initiate(selectedMethod!); setDisplayInitiate(false); } }  />
                    </FormGroup>
                )}
            </Form>
        );
    }

    return (
        <Form>
            <FormGroup>
                <Label for="verifyCode">
                    Enter the confirmation code you received
                </Label>
                <Input
                    id="verifyCode"
                    name="verifyCode"
                    placeholder="enter the verification code"
                    type="text"
                    value={code}
                    onChange={e => setCode(e.target.value)}
                    valid={code.length >= 6}
                    invalid={code.length < 6}
                />

            </FormGroup>
            {isValidationInError && <FormGroup><UncontrolledAlert color="danger">There was a problem verifying the code. Please re-enter it and try again.</UncontrolledAlert></FormGroup>}
            <BusyButton
                disabled={code.length < 6}
                isBusy={isValidateLoading}
                label="Validate"
                onClick={() => validate({methodId: selectedMethod!.methodId, providerId: selectedMethod!.providerId, token: initiateResult.token, code})}
            />
            <Button onClick={() => setDisplayInitiate(true) } color="link">I didn't receive a code</Button>
        </Form>
    );
}

export default InitiateMfa;

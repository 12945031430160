import { api } from './api';

type SsoLoginResponse = {
    value: string
}

type EnrolledAccountsResponse = {
    accountIds: string[];
    accountNames: FullAccountNameDetails[] | undefined;
}

export type AccountNameDetails = {
    platformId: string,
    name: string
}

export type FullAccountNameDetails = {
    platformId: string,
    name: string,
    accountNamePrompt: string
}

const ayrshareApi = api.injectEndpoints({
    endpoints: (build) => ({
        ssoLogin: build.mutation<SsoLoginResponse, void>({
            query: (request: void) => ({
                url: 'ayrshare/SSO/Login',
                method: 'POST',
                body: request
            })
        }),
        getActiveAccounts: build.mutation<EnrolledAccountsResponse, void>({
            query: () => ({
                url: 'ayrshare',
                method: 'GET'
            }),
        }),
        setAccountNames: build.mutation<void, AccountNameDetails[]>({
            query: (request: AccountNameDetails[]) => ({
                url: 'ayrshare/Accounts/Names',
                method: 'POST',
                body: {accountNames: request}
            }),
            invalidatesTags: ['SocialProfiles']
        }),
    })
});

export const {
    useSsoLoginMutation,
    useGetActiveAccountsMutation,
    useSetAccountNamesMutation
} = ayrshareApi;

export const {
    endpoints: {
        ssoLogin,
        getActiveAccounts,
        setAccountNames
    },
} = ayrshareApi;


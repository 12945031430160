import { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Container, Form, FormGroup, Button, Row } from 'reactstrap';
import Login from '../components/Login/login';

const LoginView = () => {

    return (
        <Container>
            <Row>
                <Login />
            </Row>
            <Row>
                <Link to="/forgotPassword">I've forgotten my password</Link>
            </Row>
            <Row>
                <Link to="/register">Create an account</Link>
            </Row>
        </Container>
    );
}

export default LoginView;

import { ProductResponse } from '../../redux/api/productApi';
import instagram from '../../images/Instagram_Glyph_Gradient.svg';
import facebook from '../../images/Facebook_Logo_Primary.png';
import empty from '../../images/empty.jpg';

type SocialAuditProductNameArgs = {
    socialPlatformId: string;
}

export const SocialAuditProductName = ({ socialPlatformId } : SocialAuditProductNameArgs) => {
    switch (socialPlatformId) {
        case "facebook":
            return (<>
                Audit Facebook Account
            </>);
        case "instagram":
            return (<>
                Audit Instagram Account
            </>)
        case "youtube":
            return (<>
                Audit Youtube Channel
            </>
            )
        default:
            return (<span>{socialPlatformId}</span>)
    }
}
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import loginReducer from './loginSlice';
import paymentReducer from './paymentSlice';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api } from './api/api';

export const store = configureStore({
    reducer: {
        login: loginReducer,
        payment: paymentReducer,
        [api.reducerPath]: api.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(api.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

import { ReactNode } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { useConfigQuery } from '../../redux/api/paymentApi';

type Props = {
    children: ReactNode | undefined
}

var stripePromise : Promise<Stripe | null> | undefined = undefined;

const PaymentWrapper = ({ children }: Props) => {
    const { data: config } = useConfigQuery();

    if (!stripePromise) {
        if (!config)
            return (null);
        stripePromise = loadStripe(config.id);
    }
        
    return (<Elements stripe={stripePromise}>{children}</Elements>);
}

export default PaymentWrapper;

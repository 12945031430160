import { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Container, Form, FormGroup, Button, Row } from 'reactstrap';
import Login from '../components/Login/login';
import VerifyEmail from '../components/Login/verifyEmail';
import Register from '../components/Login/register';

const RegisterView = () => {

    return (
        <Container>
            <Row>
                <Register onRegister={() => console.log("register") } />
            </Row>
            <Row>
                <Link to="/login">I already have an account</Link>
            </Row>
        </Container>
    );
}

export default RegisterView;

import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from 'reactstrap';
import linkedin from '../images/linkedin.svg';
import facebook from '../images/logo-facebook.svg';
import instagram from '../images/logo-instagram.svg';
import twitter from '../images/twitter.svg';
import './Footer.css';

const Footer = () => {


    return (
        <div className="footer">
            <Container>
                <Row>
                    <Col md="9" sm="8" className="footer-list">
                        <div className="footer-logo-text">infiniscape</div>
                        <div>
                            <p className="font-18 poppins-bold">Sparking a revolution of kindness and<br></br> authenticity in social media.</p>
                        </div>
                    </Col>
                    <Col md="3" sm="4" className="footer-list">
                        <div><Link to="/aboutus">About Us</Link></div>
                        <div><Link to="/termsandconditions">Terms & Conditions</Link></div>
                        <div><a href={`mailto:support@infiniscape-inc.com`} target="_blank">Contact Us</a></div>
                        <div><Link to="/privacy">Privacy Policy</Link></div>
                    </Col>
                </Row>
                <Row>
                    <Col md="9" sm="8">
                        <div >
                            <a href="https://www.facebook.com/Infiniscape/" target="_blank"><img src={facebook} className="mh-4" alt="facebook logo" /></a>
                            <a href="https://www.instagram.com/kyndr_app/?hl=en" target="_blank"><img src={instagram} className="mh-4" alt="instagram logo" /></a>
                            <a href="https://twitter.com/kyndr_social" target="_blank"><img src={twitter} className="mh-4" alt="twitter logo" /></a>
                            <a href="https://www.linkedin.com/company/70992966" target="_blank"><img src={linkedin} alt="linkedin logo" /></a>
                        </div>
                        <p className="poppins-light pt15 font-18">&copy; 2023 - Infiniscape Incorporated</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );

}

export default Footer;

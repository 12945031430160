import React, { useEffect, useState } from 'react';
import { BusyButton } from '../Buttons/busyButton';
import { Container, Row, Form, FormGroup, Label, Input, FormFeedback, Button, Spinner, UncontrolledAlert } from 'reactstrap';
import { selectLoginState, selectTemporaryToken } from '../../redux/loginSlice';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { RegistrationRequirement, useConfirmEmailMutation, useGetRegistrationRequirementsQuery, useLoginMutation, useRegisterMutation, useResendEmailConfirmationMutation } from '../../redux/api/loginApi';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

type ValidationCheck = {
    message: string,
    error: boolean
}

type RegistrationError = {
    isEmailInUse: boolean,
    isLoginNameInUse: boolean
}

type RegisterProps = {
    onRegister: () => void
}

const Register = ({onRegister} : RegisterProps) => {
    const { data: registrationRequirements } = useGetRegistrationRequirementsQuery();
    const [sendRegisterRequest, { isLoading, isError, error, isSuccess }] = useRegisterMutation();
    const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const usernameRegex = /[^A-Za-z0-9]/g;

    const [email, setEmail] = useState<string>();
    const [emailErrors, setEmailErrors] = useState<ValidationCheck[]>([]);
    const [loginName, setLoginName] = useState<string>();
    const [loginNameErrors, setLoginNameErrors] = useState<ValidationCheck[]>([]);
    const [password, setPassword] = useState<string>();
    const [passwordErrors, setPasswordErrors] = useState<ValidationCheck[]>([]);
    const [confirmPassword, setConfirmPassword] = useState<string>();

    useEffect(() => {
        if (!isError || !error) return;
        const typedError = error as FetchBaseQueryError;
        if (!typedError.data) return;
        const errorData = typedError.data as RegistrationError;
        if (errorData.isLoginNameInUse) {
            setLoginNameErrors([{message: "not already been in use", error: true}]);
        }
        if (errorData.isEmailInUse) {
            setEmailErrors([{ message: "The email is already in use", error: true }]);
        }

        console.log({ name: "PJH useEffect", error, isError });
    }, [isError, error]);

    useEffect(() => {
        if (isSuccess) onRegister();
    }, [isSuccess])

    const register = () => {
        console.log({ name: "PJH register"})
        sendRegisterRequest({ loginName: loginName ?? "", password: password ?? "", email: email ?? "", allowNewsEmails: false });
        //dispatch(loginAsync({ loginName: "phil3", password: "Abcd1234", rememberMe: false }));
    }

    const setEmailValue = (value: string) => {
        const isValid = value != null && emailRegex.test(value.trim().toLowerCase());
        setEmailErrors(!isValid ? [{message: "Please enter a valid email", error: true}] : []);
        setEmail(value ? value.trim().toLowerCase() : value);
    }

    const setLoginNameValue = (value: string) => {
        var newValue = value ? value.trim().replace(usernameRegex, "").toLowerCase() : value;
        setLoginNameErrors(validateInput(newValue, registrationRequirements?.loginNameRequirements));
        setLoginName(newValue);
    }

    const setPasswordValue = (value: string) => {
        setPasswordErrors(validateInput(value, registrationRequirements?.passwordRequirements));
        setPassword(value);
    }

    const validateInput = (value: string, requirements: RegistrationRequirement[] | undefined) => {
        let errors: ValidationCheck[] = [];
        if (!value || value.length == 0 || !requirements)
            return errors;
        for (let i = 0; i < requirements.length; i++) {
            let requirement = requirements[i];
            switch (requirement.type) {
                case "length":
                    errors.push({
                        message: requirement.errorMessage,
                        error: requirement.minLength != null && value.length < requirement.minLength
                    });
                    break;
                case "regex":
                    errors.push({
                        message: requirement.errorMessage,
                        error: requirement.expression != null && !new RegExp(requirement.expression).test(value)
                    });
                    break;
                default:
                    break;
            }
        }
        return errors.filter(err => err.error);
    }

    return (
        <Form>
            <FormGroup>
                <Label for="registerEmail">
                    Email
                </Label>
                <Input
                    id="registerEmail"
                    name="email"
                    placeholder="enter your email"
                    type="email"
                    value={email}
                    onChange={e => setEmailValue(e.target.value)}
                    valid={email != null && email.length > 0 && emailErrors.length === 0}
                    invalid={email != null && email.length > 0 && emailErrors.length > 0}
                />
                {emailErrors.length > 0 &&
                    <FormFeedback >
                        {emailErrors.map((error, i) => <div key={i}>{error.message}</div>)}
                    </FormFeedback>
                }
            </FormGroup>
            <FormGroup>
                <Label for="registerLoginName">
                    Login Name
                </Label>
                <Input
                    id="registerLoginName"
                    name="loginName"
                    placeholder="choose a login name"
                    type="text"
                    value={loginName}
                    onChange={e => setLoginNameValue(e.target.value)}
                    valid={loginName != null && loginName.length > 0 && loginNameErrors && loginNameErrors.length == 0}
                    invalid={loginNameErrors && loginNameErrors.length > 0}
                />
                {loginNameErrors.length > 0 &&
                    <FormFeedback >
                        The loginName must:
                        {loginNameErrors.map((error, i) => <div key={i}>{error.message}</div>)}
                    </FormFeedback>
                }
            </FormGroup>
            <FormGroup>
                <Label for="registerPassword">
                    Password
                </Label>
                <Input
                    id="registerPassword"
                    name="password"
                    placeholder="choose a password"
                    type="password"
                    value={password}
                    onChange={e => setPasswordValue(e.target.value)}
                    valid={password != null && password.length > 0 && passwordErrors.length == 0}
                    invalid={passwordErrors.length > 0}
                />
                {passwordErrors.length > 0 &&
                    <FormFeedback >
                        The password must:
                        {passwordErrors.map((error, i) => <div key={i}>{error.message}</div>)}
                    </FormFeedback>
                }
            </FormGroup>
            <FormGroup>
                <Label for="registerConfirmPassword">
                    Confirm Password
                </Label>
                <Input
                    id="registerConfirmPassword"
                    name="confirmPassword"
                    placeholder="re-type your password"
                    type="password"
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                    valid={confirmPassword != null && confirmPassword.length > 0 && password === confirmPassword}
                    invalid={confirmPassword != null && confirmPassword.length > 0 && password !== confirmPassword}
                />
                {passwordErrors.length > 0 &&
                    <FormFeedback >
                        The password must:
                        {passwordErrors.map((error, i) => <div key={i}>{error.message}</div>)}
                    </FormFeedback>
                }
            </FormGroup>
            {isError && <FormGroup><UncontrolledAlert color="danger">There was a problem registering as a new user. Please try again.</UncontrolledAlert></FormGroup>}

            <BusyButton
                label="Submit"
                onClick={register}
                isBusy={isLoading}
                disabled={email == null || email.length === 0 || emailErrors.length > 0
                    || loginName == null || loginName.length === 0 || loginNameErrors.length > 0
                    || password == null || password.length === 0 || passwordErrors.length > 0
                    || confirmPassword !== password}
            />
        </Form>
    );
}

export default Register;

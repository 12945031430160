import React from 'react'; 

class _StorageService {
    constructor() { }

    get<T>(key: string): T | null {
        var value = localStorage.getItem(key);
        if (value == null)
            value = sessionStorage.getItem(key);
        if (value == null)
            return value;
        return JSON.parse(value);
    }

    set<T>(key: string, value: T, isPersistent: boolean): boolean {
        if (value == null)
            return this.remove(key);

        if (isPersistent) {
            localStorage.setItem(key, JSON.stringify(value));
        } else {
            sessionStorage.setItem(key, JSON.stringify(value));
        }
        return true;
    }

    remove(key: string): boolean {
        localStorage.removeItem(key);
        sessionStorage.removeItem(key);

        return true;
    }
}

var StorageService = new _StorageService();
export default StorageService;
import React, { useEffect, useState } from 'react';
import { BusyButton } from '../Buttons/busyButton';
import { selectLoginState, selectTemporaryToken } from '../../redux/loginSlice';
import { Container, Row, Form, FormGroup, Label, Input, FormFeedback, Button, Spinner, UncontrolledAlert } from 'reactstrap';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { useConfirmEmailMutation, useGetRegistrationRequirementsQuery, useLoginMutation, useResendEmailConfirmationMutation } from '../../redux/api/loginApi';

const Login = () => {
    const isLoggedIn = useAppSelector(selectLoginState);
    const temporaryToken = useAppSelector(selectTemporaryToken);
    const [enteredCode, setEnteredCode] = useState<string>();
    const [loginName, setLoginName] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const { data: registrationRequirements } = useGetRegistrationRequirementsQuery();
    const dispatch = useAppDispatch();
    const [
        sendLogin, // This is the mutation trigger
        { isLoading, isError }, // This is the destructured mutation result
    ] = useLoginMutation()

    const login = () => {
        sendLogin({ loginName: loginName, password: password, rememberMe: false });
    }

    return (
        <Form>
            <FormGroup>
                <Label for="loginName">
                    Login Name
                </Label>
                <Input
                    id="loginName"
                    name="loginName"
                    placeholder="enter your login name"
                    type="text"
                    value={loginName}
                    onChange={e => setLoginName(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="password">
                    Password
                </Label>
                <Input
                    id="password"
                    name="password"
                    placeholder="enter your password"
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
            </FormGroup>
            {isError && <FormGroup><UncontrolledAlert color="danger">There was a problem logging in. Please try again.</UncontrolledAlert></FormGroup>}
            <BusyButton
                label="Log In"
                onClick={login}
                isBusy={isLoading}
                disabled={password.length === 0 || loginName.length === 0 }
            />
        </Form>
    );
} 

export default Login;

import { useEffect } from 'react'
import { Container, Form, FormGroup, Button, Row } from 'reactstrap';
import Login from '../components/Login/login';
import VerifyEmail from '../components/Login/verifyEmail';

const VerifyEmailView = () => {

    return (
        <Container>
            <Row>
                <VerifyEmail onVerified={() => console.log("Verified") } />
            </Row>
        </Container>
    );
}

export default VerifyEmailView;

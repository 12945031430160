import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import StorageService from '../services/storageService';
import  { config, PaymentConfig } from './api/paymentApi';
import { Stripe, loadStripe } from '@stripe/stripe-js';

export interface PaymentState {
    config: PaymentConfig | undefined,
}

// ToDO: We also need to get the token from the storage
const initialState: PaymentState = {
    config: undefined,
};

export const paymentSlice = createSlice({
    name: 'payment',
    initialState: initialState,
    reducers: {
        
    },
});


export default paymentSlice.reducer;

import { useEffect } from 'react'
import { Link as ScrollLink } from 'react-scroll';
import { Container } from 'reactstrap';

const PrivacyPolicyView = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <Container>
            <h1>Privacy Policy</h1>
            <p>Effective as of July 27, 2022</p>
            <p>This Privacy Policy describes how Infiniscape, Inc. 
                ("<b>Infiniscape</b>," "<b>we</b>", "<b>us</b>" or "<b>our</b>") handles personal information that we collect 
                through our digital properties that link to this Privacy Policy, including our website and mobile application 
                (collectively, the "<b>Service</b>"), as well as through social media, our marketing activities, our live events and other activities described in this Privacy Policy. </p>

            <h2>Index</h2>
            <ul className='no-bullets'>
                <li><ScrollLink className='scrolllink' to="personalinfo" spy={true} smooth={true} offset={-100}>Personal information we collect</ScrollLink></li>
                <li><ScrollLink className='scrolllink' to="personalinfouse" spy={true} smooth={true} offset={-100}>How we use your personal information</ScrollLink></li>
                <li><ScrollLink className='scrolllink' to="personalinfoshare" spy={true} smooth={true} offset={-100}>How we share your personal information</ScrollLink></li>
                <li><ScrollLink className='scrolllink' to="yourchoices" spy={true} smooth={true} offset={-100}>Your choices</ScrollLink></li>
                <li><ScrollLink className='scrolllink' to="othersites" spy={true} smooth={true} offset={-100}>Other sites and services</ScrollLink></li>
                <li><ScrollLink className='scrolllink' to="security" spy={true} smooth={true} offset={-100}>Security</ScrollLink></li>
                <li><ScrollLink className='scrolllink' to="internationaldatatransfer" spy={true} smooth={true} offset={-100}>International data transfer</ScrollLink></li>
                <li><ScrollLink className='scrolllink' to="children" spy={true} smooth={true} offset={-100}>Children</ScrollLink></li>
                <li><ScrollLink className='scrolllink' to="changestoprivacypolicy" spy={true} smooth={true} offset={-100}>Changes to this Privacy Policy</ScrollLink></li>
                <li><ScrollLink className='scrolllink' to="howtocontactus" spy={true} smooth={true} offset={-100}>How to contact us</ScrollLink></li>


            </ul>
            <h2 id="personalinfo">Personal information we collect</h2>
            <p><b>Information you provide to us.</b> Personal information you may provide to us through the Service or otherwise includes:</p>
            <ul>
                <li><b>Contact data</b>, such as your first and last name, email address, billing 
                    and mailing addresses, and phone number.
                </li>
                <li><b>Demographic Information</b>, such as your city, state, country of residence, 
                    postal code, and age.
                </li>
                <li><b>Profile data</b>, such as the username and password that you may set to 
                    establish an online account on the Service, date of birth, redemption 
                    code, biographical details, photographs, links to your profiles on other 
                    social networks, interests, preferences, information about your participation 
                    in our contests, promotions, or surveys, and any other information that you 
                    add to your account profile.
                </li>
                <li><b>Communications that we exchange with you</b>, including when you contact us 
                    through the Service, social media, or otherwise. 
                </li>
                <li><b>Transactional Data</b>, such as information relating to or needed to 
                    complete your orders on or through the Service, including order numbers and 
                    transaction history.
                </li>
                <li><b>Financial data</b>, such as your financial account numbers or payment card 
                    information. This data is collected through our payment processor(s) as 
                    described below.
                </li>
                <li><b>Marketing data</b>, such as your preferences for receiving our marketing 
                    communications and details about your engagement with them.
                </li>
                <li><b>User-generated content</b>, such as profile pictures, photos, images, 
                    music, videos, comments, questions, messages, works of authorship, reactions to 
                    comments and posts, answers to exercises and polls, message board posts, 
                    information about which other users you are following, your attendance at live 
                    events, and other content or information that you generate, transmit, or 
                    otherwise make available on the Service, as well as associated metadata. 
                    Metadata includes information on how, when, where and by whom a piece of content 
                    was collected and how that content has been formatted or edited. Metadata also 
                    includes information that users can add or can have added to their content, such 
                    as keywords, geographical or location information, and other similar data.
                </li>
                <li><b>Promotional information</b>, including information you share when you enter a 
                    competition, promotion or complete a survey. Please note that if you
                     participate in a sweepstakes, contest or giveaway through the Service, we 
                     may ask you for your Contact Data to notify you if you win or not, to 
                     verify your identity and/or to send you prizes. In some situations, we 
                     may need additional information as a part of the entry process, such as a 
                     prize selection choice. These sweepstakes and contests are voluntary. We 
                     recommend that you read the rules and other relevant information for each 
                     sweepstakes and contest that you enter.
                </li>
                <li><b>Other data not specifically listed here</b>, which we will use as described in 
                    this Privacy Policy or as otherwise disclosed at the time of collection.
                </li>
            </ul>
            <p><b>Third-party sources</b>. We may combine personal information we receive from you with personal 
                information we obtain from other sources, such as:</p>
            <ul>
                <li><b>Public sources</b>, such as government agencies, public records, social media platforms, 
                    and other publicly available sources.
                </li>
                <li><b>Data providers</b>, such as information services and data licensors that provide 
                    demographic and other information. 
                </li>
                <li><b>Our affiliate partners</b>, such as our affiliate network provider and publishers, 
                    influencers, and promoters who participate in our paid affiliate programs.
                </li>
                <li><b>Marketing partners</b>, such as joint marketing partners and event co-sponsors.
                </li>
                <li><b>Third-party services</b>, such as social media services, that you use to log into, 
                    or otherwise link to, your Service account. This data may include your username, 
                    profile picture and other information associated with your account on that third-party 
                    service that is made available to us based on your account settings on that service.
                </li>
            </ul>
            <p><b>Automatic data collection.</b> We, our service providers, and our business partners may 
                automatically log information about you, your computer or mobile device, and your interaction 
                over time with the Service, our communications and other online services, such as:
            </p>
            <ul>
                <li><b>Device data</b>, such as your computer's or mobile device's operating system type and 
                    version, manufacturer and model, browser type, screen resolution, RAM and disk size, 
                    CPU usage, device type (e.g., phone, tablet), IP address, unique identifiers (including 
                    identifiers used for advertising purposes), language settings, mobile device carrier, 
                    radio/network information (e.g., Wi-Fi, LTE, 3G), and general location information such as 
                    city, state or geographic area.
                </li>
                <li><b>Online activity data</b>, such as pages or screens you viewed, how long you spent on a page 
                    or screen, whether you registered for and attended online events,  the website you visited 
                    before browsing to the Service, navigation paths between pages or screens, information 
                    about your activity on a page or screen, access times and duration of access, and whether 
                    you have opened our emails or clicked links within them.
                </li>
                <li><b>Precise geolocation data</b> when you authorize our mobile application to access your 
                    device's location.
                </li>
            </ul>
            <p><b>Cookies and similar technologies</b>. Some of the automatic collection described above is facilitated 
                by the following technologies:</p>
            <ul>
                <li><b>Cookies</b>, which are small text files that websites store on user devices and that allow web servers 
                    to record users' web browsing activities and remember their submissions, preferences, and login 
                    status as they navigate a site. Cookies used on our sites include both “session cookies” that are 
                    deleted when a session ends, “persistent cookies” that remain longer, “first party” cookies that we 
                    place and “third party” cookies that our third-party business partners and service providers place. 
                </li>
                <li><b>Local storage technologies</b>, like HTML5 and asynchronous storage, that provide cookie-equivalent 
                    functionality but can store larger amounts of data on your device outside of your browser in 
                    connection with specific applications. 
                </li>
                <li><b>Web beacons</b>, also known as pixel tags or clear GIFs, which are used to demonstrate that a webpage 
                    or email address was accessed or opened, or that certain content was viewed or clicked. 
                </li>
                <li><b>Session Replay Technologies</b>. We use third party services provided by Hotjar that employ software 
                    code to record users' interactions with the Services in a manner that allows us to watch DVR-like 
                    replays of those user sessions. The replays include users' clicks, mobile app touches, mouse 
                    movements, scrolls [and keystrokes/key touches]* during those sessions.  These replays help us 
                    diagnose usability problems and identify areas for improvement.  You can learn more about Hotjar 
                    at hotjar.com and you can opt-out of session recording by Hotjar at hotjar.com.
                </li>
            </ul>
            <p><b>Data about others</b>. We may offer features that help users invite their friends or contacts to use the 
                Service, and we may collect contact details about these invitees so we can deliver their invitations. 
                Please do not refer someone to us or share their contact details with us unless you have their permission 
                to do so. 
            </p>

            <h2 id="personalinfouse">How we use your personal information</h2>
            <p>We may use your personal information for the following purposes or as otherwise described at the time of collection:</p>
            <p><b>Service delivery and operations</b>. We may use your personal information to:
            </p>
            <ul>
                <li>provide, operate and improve the Service and our business;</li>
                <li>establish and maintain your user profile on the Service;</li>
                <li>facilitate your invitations to friends who you want to invite to join the Service;</li>
                <li>enable security features of the Service, such as by sending you security codes via email or SMS, 
                    and remembering devices from which you have previously logged in;</li>
                <li>communicate with you about the Service, including by sending announcements, 
                    updates, security alerts, and support and administrative messages;</li>
                <li>understand your needs and interests, and personalize your experience with the Service and our 
                    communications; and</li>
                <li>provide support for the Service, and respond to your requests, questions and feedback.</li>
            </ul>
            <p><b>Research and development</b>. We may use your personal information for research and development purposes, 
                including to analyze and improve the Service and our business.  As part of these activities, we may 
                create aggregated, de-identified or other anonymous data from personal information we collect. We make 
                personal information into anonymous data by removing information that makes the data 
                personally identifiable to you.  We may use this anonymous data and share it with third parties for our 
                lawful business purposes, including to analyze and improve the Service and promote our business.</p>
            <p><b>Marketing and advertising</b>.  We, our service providers and our third-party advertising partners may 
                collect and use your personal information for marketing and advertising purposes:
            </p>
            <ul>
                <li><b>Direct marketing</b>. We may send you direct marketing communications.  You may opt-out of our 
                    marketing communications as described in the&nbsp;
                    <ScrollLink className='scrolllink' to="optoutofcommunications" spy={true} smooth={true} offset={-100}>Opt-out of marketing</ScrollLink> section below. </li>
                <li><b>Interest-based advertising</b>. Our third-party advertising partners may use cookies and similar 
                    technologies to collect information about your interaction (including the data described in the 
                    automatic data collection section above) with the Service, our communications and other online 
                    services over time, and use that information to serve online ads that they think will interest you. 
                    This is called interest-based advertising. We may also share information about our users with these 
                    companies to facilitate interest-based advertising to those or similar users on other online platforms. 
                    You can learn more about your choices for limiting interest-based advertising in the&nbsp;
                    <ScrollLink className='scrolllink' to="yourchoices" spy={true} smooth={true} offset={-100}>Your Choices</ScrollLink>&nbsp;
                    section below. 
                </li>
            </ul>
            <p><b>Compliance and protection</b>. We may use your personal information to:</p>
            <ul>
                <li>comply with applicable laws, lawful requests, and legal process, such as to respond to 
                    subpoenas or requests from government authorities;</li>
                <li>protect our, your or others’ rights, privacy, safety or property (including by making and defending legal claims);</li>
                <li>audit our internal processes for compliance with legal and contractual requirements or our internal policies; </li>
                <li>enforce the terms and conditions that govern the Service; and</li>
                <li>prevent, identify, investigate and deter fraudulent, harmful, unauthorized, unethical or illegal 
                    activity, including cyberattacks and identity theft.</li>
            </ul>
            <p><b>With your consent</b>.  In some cases, we may specifically ask for your consent to collect, 
                use or share your personal information, such as when required by law.</p>
            <p><b>Cookies and similar technologies</b>. In addition to the other uses included in this section, we may use 
                the Cookies and similar technologies described above for the following purposes:
            </p>
            <ul>
                <li><b>Technical operation</b>. To allow the technical operation of the Service, such as by 
                    remembering your selections and preferences as you navigate the site, and whether you are 
                    logged in when you visit password protected areas of the Service.</li>
                <li><b>Functionality</b>. To enhance the performance and functionality of our services.</li>
                <li><b>Advertising</b>. To help our third-party advertising partners collect information about 
                    how you use the Service and other online services over time, which they use to show you ads 
                    on other online services they believe will interest you and measure how the ads perform.</li>
                <li><b>Analytics</b>. To help us understand user activity on the Service, including which pages are 
                    most and least visited and how visitors move around the Service, as well as user interactions 
                    with our emails. For example, we use Google Analytics for this purpose. You can learn more about 
                    Google Analytics and how to prevent the use of Google Analytics relating to your use of our sites 
                    here: <a href="https://tools.google.com/dlpage/gaoptout?hl=en">https://tools.google.com/dlpage/gaoptout?hl=en</a>.
                </li>
            </ul>
            <p><b>Retention</b>. We generally retain personal information to fulfill the purposes for which we collected it, 
                including for the purposes of satisfying any legal, accounting, or reporting requirements, to establish or 
                defend legal claims, or for fraud prevention purposes.  To determine the appropriate retention period for 
                personal information, we may consider factors such as the amount, nature, and sensitivity of the 
                personal information, the potential risk of harm from unauthorized use or disclosure of your personal 
                information, the purposes for which we process your personal information and whether we can achieve 
                those purposes through other means, and the applicable legal requirements.</p>
            <p>When we no longer require the personal information we have collected about you, we may either delete it, 
                anonymize it, or isolate it from further processing.  </p>
            <h2 id="personalinfoshare">How we share your personal information</h2>
            <p>We may share your personal information with the following parties and as otherwise described in this 
                Privacy Policy or at the time of collection.</p>
            <p><b>Affiliates</b>.  Our corporate parent, subsidiaries, and affiliates.</p>
            <p><b>Service providers</b>.  Third parties that provide services on our behalf or help us operate the Service or 
                our business (such as hosting, information technology, customer support, email delivery, marketing, 
                consumer research and website analytics).</p>
            <p><b>Payment processors</b>. Any payment card information you use to make a purchase on the Service is collected 
                and processed directly by our payment processors, such as Stripe. Stripe may use your payment data in accordance with its privacy 
                policy, <a href="https://stripe.com/privacy">https://stripe.com/privacy</a>.</p>
            <p><b>Advertising partners.</b>  Third-party advertising companies for the interest-based advertising purposes 
                described above. </p>
            <p><b>Third parties designated by you</b>. We may share your personal data with third parties where you have 
                instructed us or provided your consent to do so. For example, we may share your personal information with 
                third-party advertisers with whom we are collaborating to offer you additional services such as sweepstakes, 
                raffles and promotions. We will share personal information that is needed for these other companies to provide 
                the services that you have requested. </p>
            <p><b>Business and marketing partners</b>. Third parties with whom we co-sponsor events or promotions, with whom 
                we jointly offer products or services, or whose products or services may be of interest to you.</p>
            <p><b>Linked third-party services</b>. If you log into the Service with, or otherwise link your Service account 
                to, a social media or other third-party service, we may share your personal information with that third-party 
                service. The third party’s use of the shared information will be governed by its privacy policy and the 
                settings associated with your account with the third-party service.</p>
            <p><b>Professional advisors</b>.  Professional advisors, such as lawyers, auditors, bankers and insurers, where 
                necessary in the course of the professional services that they render to us.</p>
            <p><b>Authorities and others</b>. Law enforcement, government authorities, and private parties, as we believe 
                in good faith to be necessary or appropriate for the compliance and protection purposes described above. </p>
            <p><b>Business transferees.</b>  Acquirers and other relevant participants in business transactions (or 
                negotiations of or due diligence for such transactions) involving a corporate divestiture, merger, 
                consolidation, acquisition, reorganization, sale or other disposition of all or any portion of the business 
                or assets of, or equity interests in, [CompanyName] or our affiliates (including, in connection with a 
                bankruptcy or similar proceedings).</p>
            <p><b>Other users and the public.</b>  Your profile and other user-generated content may be visible to other 
                users of the Service and the public. For example, other users of the Service or the public may have access 
                to your information if you chose to make your profile or other personal information available to them through 
                the Service, such as when you provide comments, reviews, survey responses, or share other 
                content. This information can be seen, collected and used by others, including being cached, copied, screen 
                captured or stored elsewhere by others (e.g., search engines), and we are not responsible for any such use 
                Sof this information.
            </p>
            <h2 id="yourchoices">Your choices</h2>
            <p><b>Access or update your information</b>. If you have registered for an account with us through the Service, 
                you may review and update certain account information by logging into the account. 
            </p>
            <p id="optoutofcommunications"><b>Opt-out of communications.</b>  You may opt-out of marketing-related emails by 
                following the opt-out or unsubscribe instructions at the bottom of the email, or by&nbsp;
                <ScrollLink className='scrolllink' to="howtocontactus" spy={true} smooth={true} offset={-100}>contacting us</ScrollLink>.
                Please note that if 
                you choose to opt-out of marketing-related emails, you may continue to receive service-related and other 
                non-marketing emails.  
            </p>
            <p><b>Cookies and similar technologies</b>. Most browsers let you remove or reject cookies.  To do this, follow 
                the instructions in your browser settings.  Many browsers accept cookies by default until you change your 
                settings.  Please note that if you set your browser to disable cookies, the Service may not work properly. 
                For more information about cookies, including how to see what cookies have been set on your browser and how 
                to manage and delete them, visit <a href="www.allaboutcookies.org">www.allaboutcookies.org</a>. You can also configure your device to prevent images 
                from loading to prevent web beacons from functioning. </p>
            <p><b>Privacy settings</b>. We make available certain privacy settings on the Service, including options to control 
                who follows you, privacy of profile and to block users.</p>
            <p><b>Advertising choices</b>.  You can limit use of your information for interest-based advertising by:</p>
            <ul>
                <li><b>Browser settings.</b> Blocking third-party cookies in your browser settings.</li>
                <li><b>Privacy browsers/plug-ins.</b> By using privacy browsers or ad-blocking browser plug-ins 
                    that let you block tracking technologies.</li>
                <li><b>Platform settings.</b> Google and Facebook offer opt-out features that let you opt-out 
                    of use of your information for interest-based advertising:
                    <ul>
                        <li>Google: <a href="https://adssettings.google.com/">https://adssettings.google.com/</a></li> 
                        <li>Facebook: <a href="https://www.facebook.com/about/ads">https://www.facebook.com/about/ads</a></li>
                    </ul>
                </li>
                <li><b>Ad industry tools.</b> Opting out of interest-based ads from companies participating in the 
                    following industry opt-out programs: 
                    <ul>
                        <li>Network Advertising Initiative: <a href="http://www.networkadvertising.org/managing/opt_out.asp">http://www.networkadvertising.org/managing/opt_out.asp</a> </li>
                        <li>Digital Advertising Alliance: optout.aboutads.info.</li>
                        <li>AppChoices mobile app, available at <a href="https://www.youradchoices.com/appchoices">https://www.youradchoices.com/appchoices</a>, which will allow you to opt-out of interest-based ads in mobile apps served by participating members of the Digital Advertising Alliance.</li>
                    </ul>
                </li>
                <li><b>Mobile settings.</b> Using your mobile device settings to limit use of the advertising 
                    ID associated with your mobile device for interest-based advertising purposes.</li>
            </ul>
            <p>You will need to apply these opt-out settings on each device from which you wish to opt-out.</p>
            <p>We cannot offer any assurances as to whether the companies we work with participate in the 
                opt-out programs described above. </p>
            <p><b>Do Not Track.</b> Some Internet browsers may be configured to send “Do Not Track” 
                signals to the online services that you visit.  We currently do not respond to “Do Not Track” 
                or similar signals.  To find out more about “Do Not Track,” please visit 
                <a href="http://www.allaboutdnt.com">http://www.allaboutdnt.com</a>.</p>
            <p><b>Declining to provide information</b>. We need to collect personal information to provide 
                certain services.  If you do not provide the information we identify as required or mandatory, 
                we may not be able to provide those services.</p>
            <p><b>Linked third-party platforms</b>. If you choose to connect any other third-party account 
                to the Service, you may be able to use your settings in your account with that platform to 
                limit the information we receive from it. If you revoke our ability to access information 
                from a third-party platform, that choice will not apply to information that we have already 
                received from that third party.</p>
            <p><b>Delete your content or close your account</b>. You can choose to delete certain content 
                through your account.  If you wish to request to close your account, please&nbsp; 
                <ScrollLink className='scrolllink' to="howtocontactus" spy={true} smooth={true} offset={-100}>contact us</ScrollLink>.</p>

            <h2 id="othersites">Other sites and services</h2>
            <p>The Service may contain links to websites, mobile applications, and other online services 
                operated by third parties.  In addition, our content may be integrated into web pages or 
                other online services that are not associated with us.  These links and integrations are 
                not an endorsement of, or representation that we are affiliated with, any third party.  
                We do not control websites, mobile applications or online services operated by third 
                parties, and we are not responsible for their actions. We encourage you to read the 
                privacy policies of the other websites, mobile applications and online services you use.
            </p>
            <h2 id="security">Security</h2>
            <p>We employ a number of technical, organizational and physical safeguards designed to 
                protect the personal information we collect.  However, security risk is inherent in 
                all internet and information technologies and we cannot guarantee the security of your 
                personal information.</p>
            <h2 id="internationaldatatransfer">International data transfer</h2>
            <p>We are headquartered in the United States and may use service providers that operate 
                in other countries. Your personal information may be transferred to the United States 
                or other locations where privacy laws may not be as protective as those in your state, 
                province, or country.  </p>
            <h2 id="children">Children</h2>
            <p>The Service is not intended for use by anyone under 16 years of age. If you are a parent 
                or guardian of a child from whom you believe we have collected personal information 
                in a manner prohibited by law, please&nbsp;
                <ScrollLink className='scrolllink' to="howtocontactus" spy={true} smooth={true} offset={-100}>contact us</ScrollLink>. 
                If we learn that we have collected personal information through the Service from a 
                child without the consent of the child’s parent or guardian as required by law, we 
                will comply with applicable legal requirements to delete the information.</p>
            <h2 id="changestoprivacypolicy">Changes to this Privacy Policy</h2>
            <p>We reserve the right to modify this Privacy Policy at any time. If we make material 
                changes to this Privacy Policy, we will notify you by updating the date of this Privacy 
                Policy and posting it on the Service or other appropriate means.  Any modifications to 
                this Privacy Policy will be effective upon our posting the modified version (or as otherwise 
                indicated at the time of posting). In all cases, your use of the Service after the 
                effective date of any modified Privacy Policy indicates your acceptance of the modified 
                Privacy Policy.</p>
            <h2 id="howtocontactus">How to contact us</h2>
            <ul>
                <li><b>Email:</b> <a href="mailto:support@infiniscape-inc.com">support@infiniscape-inc.com</a></li>
                <li><b>Mail:</b> Infiniscape Incorporated, 28 Jones St STE 104 Setauket, NY 11733</li>
            </ul>        
        </Container>
    );
}

export default PrivacyPolicyView;

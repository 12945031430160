import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react'
import type {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import { tokenReceived, logout } from '../loginSlice';
import { RootState } from '../store';
import { LoginResponse } from './loginApi';

const baseQuery = fetchBaseQuery(
    {
        baseUrl: '/api/',
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).login.authToken
            console.log({ name: "PJH baseQuery", token });
            if (token) {
                // include token in req header
                headers.set('authorization', `Bearer ${token}`)
                return headers
            }
        },
    });

const refreshQuery = fetchBaseQuery(
    {
        baseUrl: '/api/authentication/Token/Refresh',
        method: 'POST',
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).login.refreshToken
            if (token) {
                // include token in req header
                headers.set('authorization', `Bearer ${token}`)
                return headers
            }
        },
    });

const shouldRetry = (url: string) => {
    return !url.startsWith('authentication') || url.startsWith('authentication/Users/EmailVerificationCode') || url.startsWith('authentication/Mfa') || url.startsWith('authentication/Manage');
}

const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    console.log({name: "PJH baseQueryWithReauth", args})
    let result = await baseQuery(args, api, extraOptions);

    // Don't bother doing anything for the authentication endpoints, which don't require re-fetching the token
    if (typeof args === "string") {
        if (!shouldRetry(args)) return result;
    } else if (!shouldRetry((args as FetchArgs).url)) {
        console.log({ name: "PJH shortcut" });
        return result;
    }

    if (result.error && result.error.status === 401) {
        // try to get a new token
        const refreshResult = await refreshQuery('', api, extraOptions);
        const refreshData = refreshResult.data as LoginResponse;
        if (refreshData) {
            // store the new token
            api.dispatch(tokenReceived({ authToken: refreshData.accessToken, refreshToken: refreshData.refreshToken }));
            // retry the initial query
            result = await baseQuery(args, api, extraOptions)
        } else {
            api.dispatch(logout());
        }
    }
    return result
}

export const api = createApi({
    reducerPath: 'splitApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Payment', 'Profile', 'Product', 'SocialProfiles'],
    // Endpoints will be added in separate files
    endpoints: () => ({}),
})

import { useEffect } from 'react'
import { Container, Form, FormGroup, Button, Row } from 'reactstrap';
import ForgotPassword from '../components/Login/forgotPassword';

const ForgotPasswordView = () => {

    return (
        <Container>
            <Row>
                <ForgotPassword/>
            </Row>
        </Container>
    );
}

export default ForgotPasswordView;

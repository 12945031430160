import { selectMemberLoginState, selectAdminLoginState } from '../redux/loginSlice';
import { useAppSelector } from '../redux/hooks';
import { AdminRoute, ProtectedRoute } from '../components/Navigation/protectedRoute';
import { Container, Row, Col, Card, CardImg, CardBody, CardHeader, CardTitle, CardText, Button } from 'reactstrap';
import DashboardView from './dashboard';
import AdminDashboardView from './adminDashboardView';
import people from '../images/climbingpeople.png';
import bannerPeople from '../images/banner-people.png';
import { Link } from "react-router-dom";

const Home = () => {
    const isMemberLoggedIn = useAppSelector(selectMemberLoginState);
    const isAdminLoggedIn = useAppSelector(selectAdminLoginState);

    // When logged in, you see the dashboard instead of the normal main page
    if (isMemberLoggedIn) {
        return (<ProtectedRoute><DashboardView /></ProtectedRoute>);
    } else if (isAdminLoggedIn) {
        return (<AdminRoute><AdminDashboardView /></AdminRoute>)
    }

    return (
        <Container>
            <Row><h1>Reinventing Social Media</h1></Row>
            <Row>
                <Col xs="8">
                    <p>Welcome to Infiniscape, where innovation meets compassion. Founded by a visionary data scientist, we are on a mission to harness technology
                        for the greater good. Our flagship products include Kyndr, a community-driven social media app designed to foster kindness and connection,
                        and our groundbreaking Social Media Audit. At Infiniscape, we're not just reimagining social media &mdash; we're revolutionizing it,
                        one act of kindness at a time. Join us in building a digital landscape where positivity thrives and meaningful connections flourish. </p>
                </Col>
                <Col xs="4">
                    <img src={people} style={{width: '100%'}} /> 
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <CardHeader>Featured Product</CardHeader>
                        <CardImg
                            alt="Card image cap"
                            src={bannerPeople}
                           
                            top
                            width="100%"
                        />
                        <CardBody>
                            <CardTitle tag="h5">
                                Social Media Audit
                            </CardTitle>
                            <CardText>
                                <i>Traditional analytics can only take you so far. Let us dive deeper into your content to see what's working &mdash; and what's not.</i>
                            </CardText>
                            <CardText>
                                We don't just crunch numbers. We decode trends, dissect themes, and provide pathways to better understand your audience. 
                                From sentiment tracking to engagement analysis, we leave no stone unturned in our journey to uncover actionable insights. 
                            </CardText>
                            <CardText>
                                <Link to="/socialaudit"><Button color="primary">Learn More</Button></Link>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Home;

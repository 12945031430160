import { useEffect } from 'react'
import { Container, Form, FormGroup, Button, Row, UncontrolledAlert } from 'reactstrap';
import ForgotPassword from '../components/Login/forgotPassword';
import { useSearchParams, Link } from 'react-router-dom';
import ResetPassword from '../components/Login/resetPassword';
import { useLocation } from 'react-router';
import { useForgotPasswordMutation } from '../redux/api/loginApi';

const ResetPasswordView = () => {
    const [searchParams] = useSearchParams();


    const token = searchParams.get('token');
    const email = searchParams.get('email');



    return (
        <Container>
            {token && <Row>
                <ResetPassword token={token} />
            </Row>}
            {!token && <Row><UncontrolledAlert color="danger">The reset token is missing or invalid.</UncontrolledAlert></Row> }
            <Row>
                <Link to="/forgotPassword">Please re-send me the link to change my password</Link>
            </Row>
        </Container>
    );
}

export default ResetPasswordView;

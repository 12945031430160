import { ReactNode, useEffect, useState } from 'react';
import { useStripe, useElements, CardElement, CardNumberElement, CardExpiryElement, CardCvcElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { ConfirmCardPaymentData } from '@stripe/stripe-js';
import {
    Container, Row, Form, FormGroup, Label, Input, FormFeedback, Button, ButtonGroup, Spinner, UncontrolledAlert, Col, UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu} from 'reactstrap';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { PaymentStatus, useCreateTransactionMutation, usePaymentMethodsQuery, useVerifyPaymentMutation } from '../../redux/api/paymentApi';
import { BusyButton } from '../Buttons/busyButton';

type Props = {
    amount: number;
    skus: string[];
    onPaymentProcessed: () => void;
    onCancel: () => void;
}

type InnerProps = {
    amount: number;
    skus: string[];
    onCompleteChange: (isComplete: boolean, saveCard: boolean) => void;
    onPaymentProcessed: () => void;
    onCancel: () => void;
}

const CardPayment = (props: Props) => {

    // TODO: Handle polling state

    return (<ChooseCardPayment {...props} />)

}

const ChooseCardPayment = (props: Props) => {
    const { skus, amount, onCancel, onPaymentProcessed } = props;
    const { data: paymentMethods, isSuccess: paymentMethodsLoaded } = usePaymentMethodsQuery(); 
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({ name: "Choose A Payment Method", id: "" });
    const [paymentSubmitted, setPaymentSubmitted] = useState(false);
    const [newCardComplete, setNewCardComplete] = useState<boolean>(false);
    const [saveCard, setSaveCard] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>('');
    const [createTransaction, { isLoading: isBusy, isError, error, isSuccess, data: transactionData }] = useCreateTransactionMutation();
    const [verifyPayment, { isError: verifyPaymentInError, error: verifyPaymentError, isSuccess: verifyPaymentSuccess, data: verifyPaymentResult }] = useVerifyPaymentMutation();
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        // If there's no payment methods, then auto select New Card
        if (paymentMethodsLoaded && paymentMethods && paymentMethods.paymentMethods.length == 0) {
            setSelectedPaymentMethod({ name: "New Card", id: "new" });
        }
    }, [paymentMethodsLoaded, paymentMethods]);

    const tryPoll = (id: string) => {
        console.log({ name: "PJH setTimeout", id })
        setTimeout(async () => {
            
            var result = await verifyPayment({ id }).unwrap();
            // Poll again if it's still pending. Otherwise don't continue to poll
            if (result.status == PaymentStatus.Open) {
                tryPoll(id);
            } else if (result.status == PaymentStatus.Complete) {
                onPaymentProcessed();
            } else if (result.status == PaymentStatus.Canceled) {
                setErrorMessage("The payment has been canceled");
            } else if (result.status == PaymentStatus.Error) {
                setErrorMessage("There was an error processing the payment");
            }
        }, 15000);
    }

    const makePayment = async () => {
        if (!stripe || !elements) return;
        setErrorMessage("");
        try {
            var result = await createTransaction({ savePaymentMethod: saveCard, skus, amount }).unwrap();
            var config = {
                payment_method: selectedPaymentMethod.id !== "new" ? selectedPaymentMethod.id :  {
                    card: elements.getElement(CardNumberElement)
                }
            } as ConfirmCardPaymentData;
            console.log({name: "PJH makePayment 1", result, config})
            
            var paymentResult = await stripe.confirmCardPayment(result.clientSecret, config);
            console.log({ name: "PJH makePayment 2", paymentResult})
            if (paymentResult.error) {
                console.log({name: "PJH ERROR", error: paymentResult.error})
                setErrorMessage(paymentResult.error.message);
                verifyPayment({ id: result.id }); // If there's an error, force checking in the backend
            } else {
                console.log({ name: "PJH makePayment before Try", id: result.id });
                // Now create a poll 
                tryPoll(result.id);
                setPaymentSubmitted(true);
            }
        }
        catch {
            setErrorMessage("Something went wrong trying to process your payment");
        }
    }

    if (paymentSubmitted) {
        return (<Col>
            {!errorMessage && <Row><Spinner /> Waiting for payment to process...</Row>}
            {errorMessage && <Row><UncontrolledAlert color="danger">{errorMessage}</UncontrolledAlert></Row>}
            <Row><Button onClick={onCancel}>Close</Button></Row>
        </Col>);
    }

    return (
        <Col>
            <Row><Col>Total payment: ${amount}</Col></Row>
            <Row><Label>Choose Payment Method</Label></Row>
                <Row><UncontrolledDropdown>
                    <DropdownToggle
                        caret
                        color="primary"
                    >
                    {selectedPaymentMethod.name }
                    </DropdownToggle>

                    <DropdownMenu>
                    {paymentMethods?.paymentMethods != null && paymentMethods.paymentMethods.map(paymentMethod =>
                        <DropdownItem key={paymentMethod.id} onClick={_ => setSelectedPaymentMethod({ name: `Card Ending in ${paymentMethod.description}`, id: paymentMethod.id })}>
                                {`Card Ending in ${paymentMethod.description}`}
                            </DropdownItem>
                        )}
                        <DropdownItem onClick={_ => setSelectedPaymentMethod({name: "New Card", id: "new"})}>
                            Use a New Card
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown></Row>
            
            
            {selectedPaymentMethod.id === "new" && <Row><NewCardPayment {...props} onCompleteChange={(isComplete, saveCard) => {
                setNewCardComplete(isComplete);
                setSaveCard(saveCard);
            }} /></Row>}
            {errorMessage && <Row><UncontrolledAlert color="danger">{errorMessage}</UncontrolledAlert></Row> }
            <Row>
                <ButtonGroup>
                    <Button onClick={onCancel}>Cancel</Button>
                    <BusyButton
                        label="Submit"
                        onClick={makePayment}
                        isBusy={isBusy}
                        disabled={selectedPaymentMethod.id === "" || (selectedPaymentMethod.id === "new" && !newCardComplete)}
                    />
                </ButtonGroup>
            </Row>
        </Col>);
}

const NewCardPayment = ({ onCompleteChange }: InnerProps) => {
    const [cardComplete, setCardComplete] = useState(false);
    const [expiryComplete, setExpiryComplete] = useState(false);
    const [cvcComplete, setCvcComplete] = useState(false);
    const [saveCard, setSaveCard] = useState(true);

    useEffect(() => {
        onCompleteChange(cardComplete && expiryComplete && cvcComplete, saveCard);
        return () => onCompleteChange(false, false); // When unmounting always reset CompleteChange to false
    }, [cardComplete, expiryComplete, cvcComplete]);

    return (<ElementsConsumer>
        {({ stripe, elements }) => (
            <Form>
                <FormGroup>
                    <Label for="cardNumber">
                        Card Number
                    </Label>
                    <CardNumberElement
                        id="cardNumber"
                        onChange={element => setCardComplete(!element.empty && element.complete )} />
                </FormGroup>
                <FormGroup>
                    <Label for="cardExpiry">
                        Card Expiry
                    </Label>
                    <CardExpiryElement
                        id="cardExpiry"
                        onChange={element => setExpiryComplete(!element.empty && element.complete)} />
                </FormGroup>
                <FormGroup>
                    <Label for="cardCvv">
                        CVV Code
                    </Label>
                    <CardCvcElement
                        id="cardCvv"
                        onChange={element => setCvcComplete(!element.empty && element.complete)} />
                </FormGroup>
                <FormGroup>
                    <Input type="checkbox" checked={saveCard} onChange={e => setSaveCard(!saveCard)} />
                    <Label for="cardCvv">
                        Save card for future payments
                    </Label>
                    
                </FormGroup>
            </Form>
        )}
    </ElementsConsumer>)
}

export default CardPayment;

import { useEffect, useState } from 'react'
import {
    Row, Card, CardBody, CardHeader, CardText, Button, Modal, ModalHeader, ModalBody, Spinner, Table, UncontrolledAlert, ButtonGroup,
    Form, FormGroup, Label, Input
} from 'reactstrap';
import { ProductStatus, useAllProductsQuery, useMyProductsQuery } from '../../redux/api/productApi';
import { useSsoLoginMutation, useGetActiveAccountsMutation, useSetAccountNamesMutation, FullAccountNameDetails } from '../../redux/api/ayrshareApi';
import { useAppDispatch } from '../../redux/hooks';
import { useNavigate, useLocation } from 'react-router-dom';
import { api } from '../../redux/api/api';
import { BusyButton } from '../../components/Buttons/busyButton';
import { SocialAuditProductName } from '../../components/Products/socialAuditProductName';
import checkMark from '../../images/Eo_circle_green_checkmark.svg';
import reload from '../../images/Reload-icon.svg';
import redX from '../../images/Red_X.svg';

type SocialAuditConnectedStatusArgs = {
    isConnected: boolean | undefined;
    isRefreshRequired: boolean;
    isRefreshing: boolean;
    onRefreshClicked: () => void;
}

type FilteredProduct = {
    socialPlatformId: string,
    sku: string,
    id: string,
    isActive: boolean | undefined;
}

const SocialAuditConnectedStatus = ({ isConnected, isRefreshRequired, isRefreshing, onRefreshClicked }: SocialAuditConnectedStatusArgs) => {
    if (!isRefreshRequired) {
        if (isConnected) {
            return (<img src={checkMark} height="40" width="40" className="mh-4" alt="Connected" />);
        } else {
            return (<img src={redX} height="40" width="40" className="mh-4" alt="Not Connected" />);
        }
    }
    if (isRefreshing) {
        return (<Spinner />);
    }
    return (<Button color="link" onClick={onRefreshClicked}><img src={reload} height="40" width="40" className="mh-4" alt="Connected" /></Button>);
}

export const SocialAuditConnectAccountsView = () => {
    const { data: allProducts, isLoading: allProductsLoading, isError: allProductsInError } = useAllProductsQuery();
    const { data: myProducts, isLoading: myProductsLoading, isError: myProductsInError } = useMyProductsQuery();
    const [ getActiveAccounts, { data: activeSocialProfiles, isSuccess: activeSocialProfilesLoaded, isLoading: activeSocialProfilesLoading, isError: activeSocialProfilesInError } ] = useGetActiveAccountsMutation();
    const [setAccountNames, { isLoading: setAccountNamesLoading, isSuccess: setAccountNamesLoadingSuccess, isError: setAccountNamesError}] = useSetAccountNamesMutation();
    const [login, { isLoading: isSsoLoading, isSuccess: isSsoSuccess, isError: isSsoError, data: ssoUrl }] = useSsoLoginMutation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [nextBusy, setNextBusy] = useState<boolean>(false);
    const [nextError, setNextError] = useState<string | undefined>(undefined);
    const [filteredProducts, setFilteredProducts] = useState<(FilteredProduct | null)[] | undefined>(undefined);
    const [connectStep, setConnectStep] = useState<number>(0);
    const [currentAccountNames, setCurrentAccountNames] = useState<FullAccountNameDetails[] | undefined>(undefined);
    const location = useLocation();
    const selectedSkus = location.state?.skus ?? []

    useEffect(() => {
        if (!allProducts || !myProducts ) {
            setFilteredProducts(undefined);
            return;
        }

        let m = myProducts.productGroups.find(g => g.name === "Social Audit")?.products.filter(p => p.status === ProductStatus.Active);
        let a = allProducts?.productGroups.find(g => g.name === "Social Audit")?.products
            .map(p => {
                let myProduct = m?.find(pp => pp.sku === p.sku);
                if (!myProduct) return null;
                let isActive = (!activeSocialProfiles || activeSocialProfilesLoading ) ? undefined
                    : activeSocialProfiles?.accountIds && activeSocialProfiles.accountIds.includes(p.socialPlatformId);
                return {
                    socialPlatformId: p.socialPlatformId,
                    sku: p.sku,
                    id: myProduct.paymentId,
                    isActive
                } as FilteredProduct
            }).filter(p => p && selectedSkus.includes(p.sku));
        setFilteredProducts(a);
    }, [selectedSkus, allProducts, myProducts, activeSocialProfiles, activeSocialProfilesLoading])
    
    useEffect(() => {
        getActiveAccounts();
    }, []);

    useEffect(() => { 
        if (!filteredProducts || !activeSocialProfiles) {
            setCurrentAccountNames(undefined);
        } else {
            setCurrentAccountNames(activeSocialProfiles.accountNames?.filter(n => filteredProducts.some(fp => fp?.socialPlatformId === n.platformId)));
        }
        
    }, [filteredProducts, activeSocialProfiles]);

    useEffect(() => {
        if (!nextBusy || !activeSocialProfilesLoaded) return;
        // Do nothing if any are undefined; wait for things to load
        if (filteredProducts && filteredProducts.some(p => p!.isActive === undefined)) return;
        setNextBusy(false);

        // Everything is good. 
        if (filteredProducts && !filteredProducts.some(p => !p?.isActive)) {

            // And navigate to the next page.
            navigate("/products/socialAudit/submit");
        } else {
            setNextError("All your accounts must be connected before you can continue");
        }
    }, [filteredProducts, nextBusy, activeSocialProfilesLoaded]);

    const openSso = async () => {
        var loginResult = await login().unwrap();
        if (loginResult.value) {
            // mark all filtered filtered products as being in need of a refresh
            markAsRefreshNeeded();
            window.open(loginResult.value, "_blank");
        }
    }

    const markAsRefreshNeeded = () => {
        setFilteredProducts(filteredProducts?.map(p => { return { ...p, isActive: undefined } as FilteredProduct; }));
    }

    // Next is disabled if any profiles are definitely not connected.
    const disableNext = !activeSocialProfilesLoaded || !filteredProducts || filteredProducts.some(p => p && p.isActive === false);

    const handleNextClick = () => {
        setNextError(undefined);
        // If you click next and there are any unknown or unconnected accounts, retrieve again
        if (filteredProducts!.some(p => p && !p.isActive)) {
            getActiveAccounts();
            setNextBusy(true);
        } else {
            navigate("/products/socialAudit/submit", {state: {skus: selectedSkus}});
        }
    }

    const handleSetAccountNamesSubmit = async () => {
        if (!currentAccountNames) return;

        try {
            await setAccountNames(currentAccountNames).unwrap();
            markAsRefreshNeeded();
            setConnectStep(2);
            // Now open the SSO
            await openSso();
        }
        catch { }
    }

    const handleConnectAccountsClicked = () => {
        if (currentAccountNames && currentAccountNames.length > 0) {
            setConnectStep(1);
        } else {
            openSso();
        }

    }


    return (
        <>
            <Row>
                <Card>
                    <CardHeader tag="h3">Social Media Audit: Submit For Review</CardHeader>
                    <CardBody>
                        <CardText>
                            <h4>Step 1. Connect your accounts</h4>
                            In order for us to perform our detailed analysis of your social media presence, you must
                            first connect all your social media accounts. If any of your accounts are not connected,
                            please click the "Connect" button below and follow the instructions. When you are satisfied, click "Next"
                        </CardText>

                        {(allProductsInError || myProductsInError || activeSocialProfilesInError) &&
                            <Row><UncontrolledAlert color="danger">There was an unexpected error obtaining information about this product</UncontrolledAlert></Row>}

                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Purchased Product</th>
                                    <th>Is Connected</th>
                                </tr>
                            </thead>
                            {(allProductsLoading || myProductsLoading ) &&
                                <Spinner />
                                }
                            {!allProductsLoading && !myProductsLoading && filteredProducts &&
                                <tbody>
                                    {filteredProducts.map(p => {
                                        if (!p) return (null);

                                        return (<tr key={p.socialPlatformId}>
                                            <td><SocialAuditProductName socialPlatformId={p.socialPlatformId} /></td>
                                            <td><SocialAuditConnectedStatus
                                                isConnected={p.isActive}
                                                isRefreshing={activeSocialProfilesLoading}
                                                isRefreshRequired={p.isActive === undefined }
                                                onRefreshClicked={() => getActiveAccounts()}
                                            /></td>
                                        </tr>);
                                    })}
                                </tbody>}
                        </Table>
                    </CardBody>
                </Card>
            </Row>
            
            <Row>
                {nextError && 
                    <CardText><UncontrolledAlert color="danger">{nextError}</UncontrolledAlert></CardText>}
                {isSsoError &&
                    <CardText><UncontrolledAlert color="danger">There was a problem opening the page to add your accounts. Please try again later.</UncontrolledAlert></CardText>}
                <ButtonGroup>
                    <BusyButton onClick={handleConnectAccountsClicked} label="Connect Accounts" isBusy={isSsoLoading} />
                    <BusyButton onClick={handleNextClick} label="Next" isBusy={nextBusy} disabled={disableNext} />
                </ButtonGroup>
            </Row>

            { connectStep === 1 &&
                <Modal isOpen={true} toggle={() => setConnectStep(0) }>
                    <ModalHeader>Set Account Names</ModalHeader>
                    <ModalBody>
                        <Form>
                            {currentAccountNames?.map((n) =>
                                <FormGroup>
                                    <Label for="accountName">
                                        {n.accountNamePrompt}
                                    </Label>
                                    <Input
                                        id="accountName"
                                        name="accountName"
                                        placeholder={`enter your ${n.accountNamePrompt}`}
                                        type="text"
                                        value={n.name}
                                        onChange={e => setCurrentAccountNames(currentAccountNames.map(nn => {
                                            if (nn.platformId !== n.platformId)
                                                return nn;
                                            return {...nn, name: e.target.value};   
                                        }))}
                                    />
                                </FormGroup>
                            )}
                            {setAccountNamesError && <FormGroup><UncontrolledAlert color="danger">There was a problem setting one or more account names</UncontrolledAlert></FormGroup>}
                            <BusyButton
                                label="Continue"
                                onClick={handleSetAccountNamesSubmit}
                                isBusy={setAccountNamesLoading}
                                disabled={currentAccountNames?.some(n => n.name.length === 0)}
                            />
                        </Form>
                    </ModalBody>
                </Modal>}
        </>);
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import StorageService from '../services/storageService';
import { login, confirmEmail, resendEmailConfirmation, register, LoginResponse, RegisterResponse, validateMfa, confirmDelete, requestDelete, RequestDeleteResponse } from './api/loginApi';

export interface LoginState {
    userId: string | undefined;
    loginName: string | undefined;
    authToken: string | undefined;
    authTokenExpires: Date | undefined;
    refreshToken: string | undefined;
    refreshTokenExpires: Date | undefined;
    isLoggedIn: boolean;
    isUnverified: boolean;
    mfaRequired: boolean;
    temporaryToken: string | undefined;
    userType: number | undefined;
}

// ToDO: We also need to get the token from the storage
const initialState: LoginState = {
    userId: undefined,
    loginName: undefined,
    authToken: undefined,
    authTokenExpires: undefined,
    refreshToken: undefined,
    refreshTokenExpires: undefined,
    isLoggedIn: false,
    isUnverified: true,
    mfaRequired: false,
    temporaryToken: undefined,
    userType: undefined
};

const TokenStorageKey = 'token';

const handleLogout = (state: LoginState) => {
    console.log({ name: "PJH handleLogout"})
    state.userId = undefined;
    state.isLoggedIn = false;
    state.loginName = undefined;
    state.authToken = undefined;
    state.refreshToken = undefined;
    state.refreshTokenExpires = undefined;
    state.userType = undefined;
    StorageService.remove(TokenStorageKey);
}

// TODO: Store in Local Storage depending on rememberMe when successful, unsuccessful, logout, or tokenReceived. 

export const loginSlice = createSlice({
    name: 'login',
    initialState: () => StorageService.get<LoginState>(TokenStorageKey) ?? initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        logout: (state) => {
            handleLogout(state);
        },
        tokenReceived: (state, action: PayloadAction<Pick<LoginState, "authToken" | "refreshToken" >>) => {
            state.isLoggedIn = true;
            state.authToken = action.payload.authToken;
            state.refreshToken = action.payload.refreshToken;
            state.loginName = action.payload.authToken;
            StorageService.set<LoginState>(TokenStorageKey, { ...state, temporaryToken: undefined }, false); // TODO: Support "Remember Me"
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addMatcher(login.matchFulfilled, (state: LoginState, action: PayloadAction<LoginResponse>) => {
                state.isLoggedIn = true;
                state.authToken = action.payload.accessToken;
                state.refreshToken = action.payload.refreshToken;
                state.loginName = action.payload.userName;
                state.userId = action.payload.id;
                state.isUnverified = action.payload.isUnverified;
                state.mfaRequired = action.payload.isMfaRequired;
                state.userType = action.payload.userType;
                StorageService.set<LoginState>(TokenStorageKey, { ...state, temporaryToken: undefined }, false); // TODO: Support "Remember Me"
            })
            .addMatcher(login.matchRejected, (state, action) => {
                handleLogout(state);
            })
            .addMatcher(confirmEmail.matchFulfilled, (state: LoginState, action: PayloadAction<LoginResponse>) => {
                state.isLoggedIn = true;
                state.authToken = action.payload.accessToken;
                state.refreshToken = action.payload.refreshToken;
                state.loginName = action.payload.userName;
                state.userId = action.payload.id;
                state.isUnverified = action.payload.isUnverified;
                state.mfaRequired = action.payload.isMfaRequired;
                state.userType = action.payload.userType;
                StorageService.set<LoginState>(TokenStorageKey, { ...state, temporaryToken: undefined }, false); // TODO: Support "Remember Me"
            })
            .addMatcher(resendEmailConfirmation.matchFulfilled, (state: LoginState, action: PayloadAction<RegisterResponse>) => {
                state.temporaryToken = action.payload.codeToken;
            })
            .addMatcher(register.matchFulfilled, (state: LoginState, action: PayloadAction<RegisterResponse>) => {
                state.temporaryToken = action.payload.codeToken;
                state.isLoggedIn = true;
                state.authToken = action.payload.accessToken;
                state.refreshToken = action.payload.refreshToken;
                state.loginName = action.payload.userName;
                state.userId = action.payload.id;
                state.isUnverified = action.payload.isUnverified;
                state.mfaRequired = action.payload.isMfaRequired;
                state.userType = 1;
                StorageService.set<LoginState>(TokenStorageKey, { ...state, temporaryToken: undefined }, false); // TODO: Support "Remember Me"
            })
            .addMatcher(validateMfa.matchFulfilled, (state: LoginState, action: PayloadAction<LoginResponse>) => {
                state.isLoggedIn = true;
                state.authToken = action.payload.accessToken;
                state.refreshToken = action.payload.refreshToken;
                state.loginName = action.payload.userName;
                state.userId = action.payload.id;
                state.isUnverified = action.payload.isUnverified;
                state.mfaRequired = action.payload.isMfaRequired;
                state.userType = action.payload.userType;
                StorageService.set<LoginState>(TokenStorageKey, { ...state, temporaryToken: undefined }, false); // TODO: Support "Remember Me"
            })
            .addMatcher(requestDelete.matchFulfilled, (state: LoginState, action: PayloadAction<RequestDeleteResponse>) => {
                state.temporaryToken = action.payload.token;
            })
            .addMatcher(confirmDelete.matchFulfilled, (state: LoginState, action: PayloadAction<void>) => {
                handleLogout(state);
            })
    },
});

export const { logout, tokenReceived } = loginSlice.actions;

export const selectLoginState = (state: RootState) => state.login.isLoggedIn;
export const selectUserId = (state: RootState) => state.login.userId;
export const selectMemberLoginState = (state: RootState) => state.login.isLoggedIn && state.login.userType === 1;
export const selectAdminLoginState = (state: RootState) => state.login.isLoggedIn && state.login.userType === 4;
export const selectUserType = (state: RootState) => state.login.userType === 1 ? "Member" : state.login.userType === 4 ? "Admin" : "Unknown";
export const selectTemporaryToken = (state: RootState) => state.login.temporaryToken;
export const selectIsEmailUnverified = (state: RootState) => state.login.isUnverified;
export const selectIsMfaRequired = (state: RootState) => state.login.mfaRequired;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
//export const selectCount = (state: RootState) => state.counter.value;

export default loginSlice.reducer;
import { useEffect } from 'react'
import { Container } from 'reactstrap';

const AboutUsView = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <Container>
            <h1>Our Story</h1>
            <p>Welcome to Infiniscape Incorporated, where we are redefining possibilities in the digital realm.</p>
            <p>Infiniscape’s founder, Elizabeth Barton, began her career as an academic astrophysicist, 
                studying the evolution of galaxies, before bringing her data science and technology skills to 
                finance and e-commerce. At Renaissance Technologies, she modeled equity and futures markets 
                using transformative new techniques. Later she joined Walmart In-Home Delivery as a senior 
                director, where she formed and led the data science team. Believing that “astrophysics, 
                quantitative finance and data science are just three forms of the same skill set,” Betsy 
                continues to focus on the big picture, serving as a board member of&nbsp;
                <a href="https://www.aura-astronomy.org/">The Association of 
                Universities for Research in Astronomy (AURA)</a>. She founded Infiniscape in 2020 and launched 
                its flagship app, Kyndr, in 2022, to spark a revolution of kindness and authenticity in social 
                media. Unlike traditional social platforms, Kyndr focuses on creating safe spaces where users 
                can genuinely connect and build communities based on shared interests and values.</p>
            <p>Infiniscape's pioneering Social Media Audit goes beyond traditional metrics and analytics. 
                It provides creators with deep insights into their followers, empowering them to cultivate 
                meaningful relationships and foster genuine engagement. We're redefining the influencer paradigm 
                by encouraging creators to prioritize community-building over mere influence, thereby creating 
                lasting impact in the digital sphere.</p>
            <p>At Infiniscape, we believe that data science has the power to transform online interactions 
                positively. Join us in reshaping the future of social media, where authenticity and community 
                are at the heart of everything we do. Discover how Infiniscape can help you unleash the true 
                potential of your digital presence. Together, let's navigate the digital landscape with kindness 
                and innovation.</p>
        </Container>
    );
}

export default AboutUsView;

import React, { Component, SetStateAction, useState } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Input, Modal, ModalHeader, ModalBody, UncontrolledAlert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { logout } from '../../redux/loginSlice';
import { selectLoginState, selectIsEmailUnverified, selectUserType, selectUserId, selectTemporaryToken } from '../../redux/loginSlice';
import { useRequestDeleteMutation, useConfirmDeleteMutation } from '../../redux/api/loginApi';
import logo from '../images/logo.png';
import { BusyButton } from '../Buttons/busyButton';

type DeleteUserType = {
    isOpen: boolean,
    setIsOpen: React.Dispatch<SetStateAction<boolean>>
}

export const DeleteUser = ({isOpen, setIsOpen} : DeleteUserType) => {
    const userId = useAppSelector(selectUserId);
    const [requestDeleteMutation, { isLoading: requestDeleteLoading, isError: requestDeleteInError, isSuccess: requestDeleteSuccess }] = useRequestDeleteMutation();
    const [confirmDeleteMutation, { isLoading: confirmDeleteLoading, isError: confirmDeleteInError, isSuccess: confirmDeleteSuccess }] = useConfirmDeleteMutation();
    const [deleteRequestState, setDeleteRequestState] = useState<number>(0);
    const [verificationCode, setVerificationCode] = useState<string | undefined>(undefined);
    const temporaryToken = useAppSelector(selectTemporaryToken);

    if (!userId)
        return (null);

    return (
        <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
            <ModalHeader>Delete Account</ModalHeader>
            {(deleteRequestState === 0 && !requestDeleteSuccess) &&
                <ModalBody>
                    <p>Are you sure you want to delete your account? Please click Continue to receive a verification code to your email.</p>
                    {requestDeleteInError && <UncontrolledAlert color="danger">There was a problem requesting to delete your account. Please try again.</UncontrolledAlert>}
                    <BusyButton
                        label="Continue"
                        onClick={() => {
                            requestDeleteMutation(userId);
                        }}
                        isBusy={requestDeleteLoading}
                    />
                    <Button onClick={() => setIsOpen(false) }>Cancel</Button>
                </ModalBody>
            }
            {(deleteRequestState === 1 || requestDeleteSuccess) && 
                <ModalBody>
                    <p>Please check your email for the verification code we have just mailed you.</p>
                    {confirmDeleteInError && <UncontrolledAlert color="danger">There was a problem trying to delete your account. Please re-enter the code and try again</UncontrolledAlert>}
                    <Input
                        id="VerificationCode"
                        name="verificationCode"
                        placeholder="enter the verification code"
                        type="text"
                        value={verificationCode}
                        onChange={e => setVerificationCode(e.target.value)}
                    />
                    <BusyButton
                        label="Submit"
                        onClick={() => {
                            confirmDeleteMutation({userId, token: temporaryToken ?? '', code: verificationCode ?? ''})
                        }}
                        isBusy={requestDeleteLoading || confirmDeleteLoading}
                    />
                    <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                    <Button color="link" onClick={() => {
                        setDeleteRequestState(1);
                        setVerificationCode('');
                        requestDeleteMutation(userId);
                    }}>Resend code</Button>
                </ModalBody>}
        </Modal>
    );

}

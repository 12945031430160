import { useState } from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardImg, CardBody, CardHeader, CardTitle, CardText, Button, Modal, ModalHeader, ModalBody, UncontrolledAlert } from 'reactstrap';
import bannerPeople from '../../images/banner-people.png';
import { selectLoginState, selectUserType } from '../../redux/loginSlice';
import { ProductStatus, useAllProductsQuery, useMyProductsQuery } from '../../redux/api/productApi';
import { useAppSelector } from '../../redux/hooks';
import CardPayment from '../../components/Payment/CardPayment';

const LoggedInSocialAuditDescriptionView = () => {
    const { data: allProducts, isError: allProductsInError } = useAllProductsQuery();
    const { data: myProducts, isError: myProductsInError } = useMyProductsQuery();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    // See if you've already purchased the product or not...
    const socialAuditGroup = myProducts?.productGroups.find(g => g.name === "Social Audit");
    const myProduct = socialAuditGroup?.products.find(p => p.sku === "669A8F13-22AA-46B1-A3D6-15691C28F2D9");
    // Get the cost of the product.
    const productDetails = allProducts?.productGroups.find(g => g.name === "Social Audit")?.products?.find(p => p.sku === "669A8F13-22AA-46B1-A3D6-15691C28F2D9");

    // Myproduct possibilities: Not bought yet. Active

    const canBuy = productDetails?.cost && myProduct?.status != ProductStatus.Active && myProduct?.status != ProductStatus.Pending
        && myProduct?.status != ProductStatus.DeliveryRequested;

    return (
        <>
            <Row>
                <Card>
                    <CardHeader>Enroll now</CardHeader>
                    <CardBody>
                        <CardText>
                            <p>Pharetra magna ac placerat vestibulum lectus. Id diam maecenas ultricies mi. Ac placerat vestibulum lectus mauris ultrices eros in cursus turpis. Faucibus turpis in eu mi bibendum neque egestas congue. Quam id leo in vitae turpis massa. Eros donec ac odio tempor orci. Ut enim blandit volutpat maecenas volutpat. Nulla aliquet enim tortor at auctor urna nunc. </p>
                        </CardText>
                        {(allProductsInError || myProductsInError) &&
                            <Row><UncontrolledAlert color="danger">There was an unexpected error obtaining information about this product</UncontrolledAlert></Row>}
                        {myProduct?.status &&
                            <CardText>
                            {myProduct.status == ProductStatus.Active ? `You enrolled in this product on ${new Date(myProduct.modifiedDateTimeUtc).toDateString()}`
                                : myProduct.status == ProductStatus.Pending ? `Your submitted payment on ${new Date(myProduct.modifiedDateTimeUtc).toDateString()} is still being processed` 
                                : myProduct.status == ProductStatus.Delivered ? `Your last report was delivered on ${new Date(myProduct.modifiedDateTimeUtc).toDateString()}` 
                                : myProduct.status == ProductStatus.DeliveryRequested ? `The report you requested on ${new Date(myProduct.modifiedDateTimeUtc).toDateString()} is still being processed` 
                                : myProduct.status == ProductStatus.Error ? `There was an error processing your last payment` 
                                : ""}
                            </CardText>}
                        {canBuy && 
                            <>
                            <Button color="primary" onClick={() => setIsModalOpen(true)}>Pay now (${productDetails.cost})</Button> 
                            </>}
                    </CardBody>
                </Card>
            </Row>
            {productDetails?.cost &&
                <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
                    <ModalHeader>Pay now</ModalHeader>
                    <ModalBody> 
                        <CardPayment
                            amount={productDetails.cost}
                            skus={[productDetails.sku]}
                            onCancel={() => { setIsModalOpen(false) }}
                            onPaymentProcessed={() => { setIsModalOpen(false)}} />
                    </ModalBody>
                </Modal>}
        </>);
}

const SocialAuditDescriptionView = () => {
    const isLoggedIn = useAppSelector(selectLoginState);
    const userType = useAppSelector(selectUserType);

    return (
        <Container>
            <Row>
                <Col><h1>Social Media Audit</h1></Col>
            </Row>
            <Row>
                <Col><img src={bannerPeople} width="100%"/></Col>
            </Row>
            <Row><h3>Your online success story starts here</h3></Row>
            <Row>
                <Col>
                    <Card body style={{ float: 'right', maxWidth: 500, margin: '20px' }}>
                        <CardTitle tag="h4">Ready to optimize your effort?</CardTitle>
                        <CardText tag="p">Here's how we'll help:</CardText>
                        <ul>
                            <li>Identification of <b>top-performing themes</b>: Uncover what truly resonates with your audience <i>and capitalize on it</i></li>
                            <li><b>Sentiment Classification:</b> Understand how your audience feels about your brand and tailor your message accordingly</li>
                            <li><b>Engagement Analysis:</b>  We go beyond likes and shares to actually <i>understand</i> how your audience is truly interacting with your content</li>
                        </ul>
                        {!isLoggedIn &&
                            <Link to="/register" state={{ loginReturn: '/socialaudit' }} ><Button color="primary">Sign up now</Button></Link>}
                    </Card>
                    <p>Are you tired of drowning in a sea of social media metrics that don't really tell you much? Say goodbye to surface-level analytics and hello to real, actionable insights with our Social media.
                    Audit service! We go beyond the surface, diving into your online presence with <b>precision</b> and <b>expertise</b>. We will help you build a true community from your followers.</p>
                    <p>We believe that creating content isn’t just about posting for the sake of it &mdash; 
                        it’s an investment in your brand’s future! And just like any investment, you want to 
                        make sure it’s paying off. That’s where we come in. Experience our unmatched efficiency 
                        with your audit ready for review in as little as 1 business day! Forget about the hassle 
                        of sending screenshots or searching for your top-performing content &mdash; 
                        simply use our secure log in to connect your accounts, and we’ll handle the rest. 
                        Plus, our satisfaction guarantee ensures that if anything feels amiss, we’re just an 
                        email away.</p>
                    <p>Don’t settle for mediocre metrics &mdash; invest in your brand’s online success with our Social Media Audit today!</p>
                </Col>
            </Row>
            {isLoggedIn && userType === "Member" && 
                <LoggedInSocialAuditDescriptionView />}
            
        </Container>
    );
}

export default SocialAuditDescriptionView;

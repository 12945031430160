import { api } from './api';
import { ProductStatus } from './productApi';

export type DeliveryRequestResponse = {
    email: string,
    userId: string,
    sku: string,
    id: string,
    title: string,
    modifiedDateTimeUtc: Date
}

type DeliveryRequestsResponse = {
    requests: DeliveryRequestResponse[]
}

export type UpdateProductStatusRequest = {
    userId: string,
    sku: string,
    id: string,
    newStatus: ProductStatus
}

const adminApi = api.injectEndpoints({
    endpoints: (build) => ({
        getDeliveryRequests: build.query<DeliveryRequestsResponse, void>({
            query: (request: void) => ({
                url: 'admin/products/deliveryRequests',
                method: 'GET'
            }),
            providesTags: ["Product"]
        }),
        updateProductStatus: build.mutation<void, UpdateProductStatusRequest>({
            query: (request: UpdateProductStatusRequest) => ({
                url: `admin/products/users/${request.userId}/deliveryRequests/${request.sku}/${request.id}/status`,
                method: 'POST',
                body: {
                    newStatus: request.newStatus
                }
            }),
            invalidatesTags: ["Product"]
        }),
    })
});

export const {
    useGetDeliveryRequestsQuery,
    useUpdateProductStatusMutation
} = adminApi;

export const {
    endpoints: {
        getDeliveryRequests,
        updateProductStatus
    },
} = adminApi;


import { api } from './api';

export type LoginRequest = {
    loginName: string,
    password: string,
    rememberMe: boolean
};

export type LoginResponse = {
    userName: string,
    tokenType: string,
    accessToken: string,
    accessTokenExpiresUtc: Date,
    refreshToken: string,
    refreshTokenExpiresUtc: Date,
    isUnverified: boolean,
    isMfaRequired: boolean,
    userType: number,
    id: string
}

export type RegisterRequest = {
    email: string,
    password: string,
    loginName: string,
    allowNewsEmails: boolean
}

export type RegisterResponse = {
    codeToken: string
    userName: string,
    tokenType: string,
    accessToken: string,
    accessTokenExpiresUtc: Date,
    refreshToken: string,
    refreshTokenExpiresUtc: Date,
    isUnverified: boolean,
    isMfaRequired: boolean,
    id: string
}

export type VerifyEmailRequest = {
    codeToken: string,
    code: string
}

export type PasswordRecoveryRequest = {
    email: string
}

export type PasswordResetRequest = {
    newPassword: string,
    token: string
}

export type RegistrationRequirement = {
    type: string,
    errorMessage: string,
    minLength?: number,
    expression?: string,
}

export type RegistrationRequirements = {
    loginNameRequirements: RegistrationRequirement[],
    passwordRequirements: RegistrationRequirement[]
}

export type MfaMethod = {
    id: string,
    description: string
}

export type MfaMethods = {
    methods: { [id: string] : MfaMethod[] }
}

export type MfaInitiationResponse = {
    token: string
}

export type MfaInitiationRequest = {
    providerId: string,
    methodId: string
}

export type MfaValidationRequest = {
    providerId: string,
    methodId: string,
    token: string,
    code: string
}

export type RequestDeleteResponse = {
    token: string
}

export type ConfirmDeleteRequest = {
    userId: string,
    token: string,
    code: string
}

const loginApi = api.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation<LoginResponse, LoginRequest>({
            query: (credentials: LoginRequest) => ({
                url: 'authentication/login',
                method: 'POST',
                body: credentials,
            }),
            invalidatesTags: ['Profile', 'Product']
        }),
        requestDelete: build.mutation<RequestDeleteResponse, string>({
            query: (userId: string) => ({
                url: `authentication/Manage/Users/${userId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Profile', 'Product']
        }),
        confirmDelete: build.mutation<void, ConfirmDeleteRequest>({
            query: (request: ConfirmDeleteRequest) => ({
                url: `authentication/Manage/Users/${request.userId}/DeleteConfirmations`,
                method: 'POST',
                body: {token: request.token, code: request.code}
            }),
            invalidatesTags: ['Profile', 'Product']
        }),
        confirmEmail: build.mutation<LoginResponse, VerifyEmailRequest>({
            query: (request: VerifyEmailRequest) => ({
                url: 'authentication/Users/EmailVerificationCode',
                method: 'POST',
                body: request,
            })
        }),
        resendEmailConfirmation: build.mutation<RegisterResponse, void>({
            query: () => ({
                url: 'authentication/Users/EmailVerificationCode/Requests',
                method: 'POST',
                body: {},
            })
        }),
        register: build.mutation<RegisterResponse, RegisterRequest>({
            query: (request: RegisterRequest) => ({
                url: 'authentication/Users',
                method: 'POST',
                body: request
            }),
            invalidatesTags: ['Profile', 'Product']
        }),
        forgotPassword: build.mutation<void, PasswordRecoveryRequest>({
            query: (request: PasswordRecoveryRequest) => ({
                url: 'authentication/ForgottenPasswords',
                method: 'POST',
                body: request
            })
        }),
        resetPassword: build.mutation<void, PasswordResetRequest>({
            query: (request: PasswordResetRequest) => ({
                url: 'authentication/PasswordReset',
                method: 'POST',
                body: request
            })
        }),
        getRegistrationRequirements: build.query<RegistrationRequirements, void>({
            query: () => 'authentication/RegistrationRequirements'
        }),
        getMfaMethods: build.query<MfaMethods, void>({
            query: () => 'authentication/Mfa/Methods',
            providesTags: [ 'Profile' ]
        }),
        initiateMfa: build.mutation<MfaInitiationResponse, MfaInitiationRequest>({
            query: (request: MfaInitiationRequest) => ({
                url: `authentication/Mfa/Methods/${encodeURIComponent(request.providerId)}/${encodeURIComponent(request.methodId)}`,
                method: 'POST'
            })
        }),
        validateMfa: build.mutation<LoginResponse, MfaValidationRequest>({
            query: (request: MfaValidationRequest) => ({
                url: `authentication/Mfa/Methods/${encodeURIComponent(request.providerId)}/${encodeURIComponent(request.methodId)}/validations`,
                method: 'POST',
                body: {
                    token: request.token,
                    code: request.code
                }
            })
        }),
        
    })
});

export const {
    useLoginMutation,
    useConfirmEmailMutation,
    useResendEmailConfirmationMutation,
    useRegisterMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useGetRegistrationRequirementsQuery,
    useGetMfaMethodsQuery,
    useInitiateMfaMutation,
    useValidateMfaMutation,
    useRequestDeleteMutation,
    useConfirmDeleteMutation
} = loginApi;

export const {
    endpoints: {
        login,
        confirmEmail,
        resendEmailConfirmation,
        register,
        forgotPassword,
        resetPassword,
        getMfaMethods,
        initiateMfa,
        validateMfa,
        requestDelete,
        confirmDelete
    },
} = loginApi;

import { api } from './api';

export type ProductResponse = {
    name: string;
    sku: string;
    cost: number;
    socialPlatformId: string;
}

type ProductGroup = {
    name: string;
    products: ProductResponse[];
}

type ProductsResponse = {
    productGroups: ProductGroup[];
}

export type MyProductResponse = {
    name: string;
    sku: string;
    status: number;
    modifiedDateTimeUtc: Date;
    title: string;
    isSubscription: boolean;
    paymentId: string;
}

export const enum ProductStatus {
    Unknown = 0,
    Active = 1,
    Expired = 2,
    Delivered = 3,
    Processing = 4,
    Pending = 5,
    Cancelled = 6,
    DeliveryRequested = 7,
    Error = 8
}

type MyProductGroup = {
    name: string;
    products: MyProductResponse[];
}

type MyProductsResponse = {
    productGroups: MyProductGroup[];
}

type RequestDeliveryProduct = {
    sku: string;
    id: string;
}

type RequestDeliveryRequest = {
    products: RequestDeliveryProduct[]
}

const productApi = api.injectEndpoints({
    endpoints: (build) => ({
        allProducts: build.query<ProductsResponse, void>({
            query: () => ({
                url: 'products/all',
                method: 'GET'
            })
        }),
        unenrolledProducts: build.query<ProductsResponse, void>({
            query: () => ({
                url: 'products/unenrolled',
                method: 'GET'
            }),
            providesTags: ['Product']
        }),
        myProducts: build.query<MyProductsResponse, void>({
            query: () => ({
                url: 'products/my',
                method: 'GET'
            }),
            providesTags: ['Product']
        }),
        requestDelivery: build.mutation<void, RequestDeliveryRequest>({
            query: (request: RequestDeliveryRequest) => ({
                url: `products/deliveryRequests`,
                method: 'POST',
                body: request
            }),
            invalidatesTags: ['Product']
        }),
    })
});


export const {
    useAllProductsQuery,
    useMyProductsQuery,
    useUnenrolledProductsQuery,
    useRequestDeliveryMutation
} = productApi;

export const {
    endpoints: {
        allProducts,
        myProducts,
        unenrolledProducts,
        requestDelivery
    },
} = productApi;


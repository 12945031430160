import { useEffect, useState } from 'react'
import { Link} from 'react-router-dom';
import { Container, Form, FormGroup, Button, ButtonGroup, Row, Spinner, Input, Label, Col } from 'reactstrap';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useUnenrolledProductsQuery, useAllProductsQuery, allProducts } from '../../redux/api/productApi';
import { SocialAuditSummary } from '../../components/Products/socialAudit';
import CardPayment from '../../components/Payment/CardPayment';

const ProductWizard = () => {
    const { id } = useParams();
    let { state } = useLocation();
    if (!state) state = {};
    const [selected, setSelected] = useState(state);
    const navigate = useNavigate();
    const { data: unenrolledProducts, isSuccess: productsLoaded } = useUnenrolledProductsQuery(); 
    const { data: allProducts, isSuccess: allProductsLoaded } = useAllProductsQuery(); 

    const handleCancelPayment = () => {
        navigate("/");
    }

    const handlePaymentComplete = () => {
        navigate("/");
    }

    if (!productsLoaded) {
        return (<Spinner />);
    }

    if (!id) {
        // On first navigation if the products are not available 
        if (!unenrolledProducts || unenrolledProducts.productGroups.length === 0) {
            return (<Navigate to="/" replace />);
        }

        // TODO: Don't automatically do this. Instead use Link and have a better UX
        return (<Navigate to={`/products/wizard/0`} />)
    }

    const productIndex = Number(id);
    if (productIndex >= unenrolledProducts.productGroups.length) {
        // On last navigation, try to get card payment sorted 
        const allSelectedProducts = allProducts?.productGroups.flatMap(g => g.products).filter(p => state[p.sku]);

        if (allSelectedProducts?.length === 0) {
            return (<Navigate to="/" replace />);
        }

        let skus: string[] = [];
        let cost = 0;
        if (state) {
            Object.keys(state).forEach((key, _) => {
                let product = allSelectedProducts?.find(p => p.sku === key);
                cost += product?.cost ?? 0;
                // TODO: Verify product exists
                if (state[key]) skus.push(key);
            });
        }
        
        // Finalizing the wizard
        return (<Col>
            <Row><CardPayment amount={cost} skus={skus} onCancel={handleCancelPayment} onPaymentProcessed={handlePaymentComplete} /></Row>
        </Col>);
    }

    // Choose an item
    const productGroup = unenrolledProducts.productGroups[productIndex];

    const handleClick = (id: string, isChecked: boolean) => { 
        let newSelected = { ...selected } as any;
        newSelected[id] = isChecked;
        setSelected(newSelected);
    }

    const formatMoney = (cost: number) =>
    {
        if (cost === 0) return "Free";
        return "$" + cost;
    }

    return (
        <>
            <Col>
            <Row>
                <SocialAuditSummary />
            </Row>

                <Row>
                    {productGroup?.products.map((item) =>
                        <FormGroup key={item.sku} check>
                            <Input type="checkbox" checked={state[item.sku]} onChange={e => handleClick(item.sku, e.target.checked) } />
                            <Label check>Yes, I would like to Enroll in {item.name} ({formatMoney(item.cost)})</Label>
                        </FormGroup>
                    )}
                </Row>
                <Row>
                    <Link
                        to={`/products/wizard/${productIndex + 1}`}
                        state={{ ...state, ...selected }}><Button color="primary">Next</Button></Link>
                </Row>
            </Col>
        </>
    )
}

export default ProductWizard;


import { Container, Form, FormGroup, Button, Row, Col, Card, CardTitle, CardHeader, CardBody, CardText, CardSubtitle, UncontrolledAlert, ButtonGroup } from 'reactstrap';
import { DeliveryRequestResponse, useGetDeliveryRequestsQuery, useUpdateProductStatusMutation, UpdateProductStatusRequest } from '../redux/api/adminApi';
import moment from "moment";
import { BusyButton } from '../components/Buttons/busyButton';
import { ProductStatus } from '../redux/api/productApi';

type DeliveryRequestArgs = {
    request: DeliveryRequestResponse;
}

const DeliveryRequest = ({ request }: DeliveryRequestArgs) => {
    const [updateProductStatus, { isLoading: isUpdateProductStatusLoading, isError: isUpdateProductStatusError }] = useUpdateProductStatusMutation();

    return (<Card>
        <CardHeader tag="h4">User: <a href={`mailto:${request.email}`}>{request.email}</a></CardHeader>
        <CardBody>
            <CardText>A Social Media Audit request was received from:</CardText>
            <dl>
                <dt>When</dt><dd>{moment(request.modifiedDateTimeUtc).local().fromNow(true)} ago</dd>
                <dt>Email</dt><dd>{request.email}</dd>
                <dt>UserId</dt><dd>{request.userId}</dd>
                <dt>Requested Product Group</dt><dd>{request.title}</dd>
                <dt>Requested SKU</dt><dd>{request.sku}</dd>
            </dl>
            {isUpdateProductStatusError && 
                <UncontrolledAlert color="danger">There was a problem updating the status</UncontrolledAlert>}
                <div style={{display: 'flex', justifyContent: 'space-between'} }>
                    <BusyButton type='secondary' isBusy={isUpdateProductStatusLoading} label="Reset Status"
                        onClick={() => updateProductStatus({ sku: request.sku, id: request.id, userId: request.userId, newStatus: ProductStatus.Active })}
                    />
                    <BusyButton isBusy={isUpdateProductStatusLoading} label="Mark as Delivered" onClick={() => updateProductStatus({ sku: request.sku, id: request.id, userId: request.userId, newStatus: ProductStatus.Delivered })} />
                </div>
        </CardBody>
    </Card>);
} 

const AdminDashboardView = () => {
    const { data: deliveryRequests, isSuccess: deliveryRequestsLoaded } = useGetDeliveryRequestsQuery();

    console.log({name: "PJH AdminDashboardView", deliveryRequests})

    return (
        <Container>
            <Row><h2>Delivery Requests</h2></Row>
                {deliveryRequestsLoaded && deliveryRequests &&
            <Row><Col>{deliveryRequests.requests.map((r) => <DeliveryRequest request={r} key={r.id} />)}</Col></Row>}
        </Container>
    );
}

export default AdminDashboardView;
import { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Container, Form, FormGroup, Button, Row, Card, CardTitle, CardBody, CardText, CardSubtitle } from 'reactstrap';
import { Navigate  } from 'react-router-dom';
import { useMyProductsQuery } from '../redux/api/productApi';
import SocialAuditDetails from '../components/Products/socialAuditDetails';

const DashboardView = () => {
    const { data: myProducts, isSuccess: myProductsLoaded } = useMyProductsQuery(); 

    if (myProductsLoaded && myProducts?.productGroups && myProducts.productGroups.length == 0) {
        // If the user has no purchased products, redirect to the wizard
        return (<Navigate to="/products/wizard" />);
    }

    return (
        <Container>
            <Row>
                <h2>Manage your subscriptions</h2>
            </Row>
            {myProducts && myProducts.productGroups.map(g =>
                <div key={g.name}>
                    {g.name === "Social Audit" && 
                        <SocialAuditDetails />}
                    
                </div>)}
            
        </Container>
    );
}

export default DashboardView;
import { Container, Form, FormGroup, Button, Row, Spinner } from 'reactstrap';

export const SocialAuditSummary = () => {
    return (
        <Container>
            <Row>
            <h2>Social Media Audit</h2>
                <p>Are you tired of drowning in a sea of social media metrics that don't really tell you much?
                    Say goodbye to surface-level analytics and hello to real, actionable insights with our Social Media Audit service!
                    We go beyond the surface, diving into your online presence with <b>precision</b> and <b>expertise</b>.
                    We will help you build a true community from your followers.</p>
                <p>Here's now we'll help:</p>
                <ul>
                    <li>Identification of <b>top-performing themes</b>: Uncover what truly resonates with your audience <i>and capitalize on it.</i></li>
                    <li><b>Sentiment Classification</b>: Understand how your audience feels about your brand and tailor your message accordingly.</li>
                    <li><b>Engagement Analysis</b>: We go beyond likes and shares to actually understand how your audience is truly interacting with your content.</li>
                </ul>

                <p>We support multiple popular social media platforms, so just sign up below to begin your online success story:</p>
            </Row>

        </Container>);
}
import { useEffect } from 'react'
import { Container, Form, FormGroup, Button, Row, Card, CardTitle, CardBody, CardText, CardSubtitle } from 'reactstrap';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useMyProductsQuery, useAllProductsQuery } from '../redux/api/productApi'; 
import { useSsoLoginMutation } from '../redux/api/ayrshareApi';


const SubscriptionView = () => {
    const { data: myProducts } = useMyProductsQuery();
    const [login, { isLoading, isSuccess, data: ssoUrl }] = useSsoLoginMutation()

    const openSso = () => {
        login();
    }

    return (
        <Container>
            <Row>
                <h1>My subscriptions and products</h1>
            </Row>
            {myProducts && myProducts.productGroups.map(g =>
                <Card key={g.name}>
                    <CardBody>
                        <CardTitle tag="h5">{g.name}</CardTitle>
                        <CardSubtitle>This is a subtitle</CardSubtitle>
                        <Button onClick={openSso}>Click to open</Button>
                    </CardBody>
                </Card>)}
        </Container>
    );
}

export default SubscriptionView;

import React, { Component, SetStateAction, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { DeleteUser } from '../components/AccountManagement/DeleteUser';

export const ManageAccountView = () => {
    const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
    
    return (
        <>
            <h2>Manage Your Account</h2>
            <DeleteUser isOpen={isDeleteOpen} setIsOpen={setIsDeleteOpen}/>
            <ul>
                <li>
                    <Button color="link" onClick={() => setIsDeleteOpen(true) }>Delete Account</Button>
                </li>
            </ul>
        </>);

}

import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import Home from "./views/Home";
import { ProtectedRoute, UnprotectedRoute, UnverifiedRoute, AdminRoute, MfaRoute } from "./components/Navigation/protectedRoute";
import ForgotPasswordView from "./views/forgotPasswordView";
import LoginView from "./views/loginView";
import RegisterView from "./views/registerView";
import ResetPasswordView from "./views/resetPasswordView";
import VerifyEmailView from "./views/verifyEmailView";
import SubscriptionView from "./views/subscriptionsView";
import ProductWizard from "./views/wizard/productWizard";
import { SocialAuditConnectAccountsView } from "./views/SocialAudit/connectAccountsView";
import AboutUsView from "./views/AboutUsView";
import TermsAndConditionsView from "./views/TermsAndConditionsView";
import InitiateMfa from "./components/Login/initiateMfa";
import SocialAuditDescriptionView from "./views/SocialAudit/SocialAuditDescriptionView";
import { ManageAccountView } from "./views/manageAccountView";
import { SocialAuditSubmitForReviewView } from "./views/SocialAudit/submitForReviewView";
import PrivacyPolicyView from "./views/PrivacyPolicyView";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/login',
        element: <UnprotectedRoute><LoginView /></UnprotectedRoute>
    },
    {
        path: '/verifyEmail',
        element: <UnverifiedRoute><VerifyEmailView /></UnverifiedRoute>
    },
    {
        path: '/initiateMfa',
        element: <MfaRoute><InitiateMfa /></MfaRoute>
    },
    {
        path: '/forgotPassword',
        element: <UnprotectedRoute><ForgotPasswordView /></UnprotectedRoute>
    },
    {
        path: '/register',
        element: <UnprotectedRoute><RegisterView /></UnprotectedRoute>
    },
    {
        path: '/Users/ForgotPassword',
        element: <UnprotectedRoute><ResetPasswordView /></UnprotectedRoute>
    },
    {
        path: '/MySubscriptions',
        element: <ProtectedRoute><SubscriptionView /></ProtectedRoute>
    },
    {
        path: '/products/wizard',
        element: <ProtectedRoute><ProductWizard /></ProtectedRoute>
    },
    {
        path: '/products/wizard/:id',
        element: <ProtectedRoute><ProductWizard /></ProtectedRoute>
    },
    {
        path: '/products/socialAudit/connect',
        element: <ProtectedRoute><SocialAuditConnectAccountsView /></ProtectedRoute>
    },
    {
        path: '/products/socialAudit/submit',
        element: <ProtectedRoute><SocialAuditSubmitForReviewView /></ProtectedRoute>
    },
    {
        path: '/aboutus',
        element: <AboutUsView/>
    },
    {
        path: '/termsandconditions',
        element: <TermsAndConditionsView />
    },
    {
        path: '/socialAudit',
        element: <ProtectedRoute notLoggedInContent={<SocialAuditDescriptionView/>}>
                    <SocialAuditDescriptionView/>
                 </ProtectedRoute>
    },
    {
        path: '/manageAccount',
        element: <ProtectedRoute><ManageAccountView /></ProtectedRoute>
    },
    {
        path: '/privacy',
        element: <PrivacyPolicyView/>
    },
];

export default AppRoutes;

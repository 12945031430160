import React, { useEffect, useState } from 'react';
import { BusyButton } from '../Buttons/busyButton';
import { selectIsEmailUnverified, selectLoginState, selectTemporaryToken } from '../../redux/loginSlice';
import { Alert, Container, Row, Form, FormGroup, Label, Input, FormFeedback, Button, Spinner, FormText, UncontrolledAlert } from 'reactstrap';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { RegistrationRequirement, useConfirmEmailMutation, useForgotPasswordMutation, useGetRegistrationRequirementsQuery, useLoginMutation, useResendEmailConfirmationMutation, useResetPasswordMutation } from '../../redux/api/loginApi';

type VerifyEmailProps = {
    onVerified: () => void;
}

const VerifyEmail = ({onVerified } : VerifyEmailProps) => {
    const isLoggedIn = useAppSelector(selectLoginState);
    const temporaryToken = useAppSelector(selectTemporaryToken);
    const isEmailUnverified = useAppSelector(selectIsEmailUnverified);
    const [code, setCode] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const { data: registrationRequirements } = useGetRegistrationRequirementsQuery();
    const dispatch = useAppDispatch();
    const [
        resendPasswordConfirmation, 
        { isLoading: isResendLoading, isError: isResendInError }, 
    ] = useResendEmailConfirmationMutation()

    const [sendVerifyEmailCode, { isLoading: isVerifyLoading, isError: isVerifyInError }] = useConfirmEmailMutation();

    useEffect(() => {
        if (!isEmailUnverified) onVerified();
    }, [isEmailUnverified]);

    // If the email is verified, hide this component 
    if (!isEmailUnverified) {
        return (null);
    }

    const resend = () => {
        resendPasswordConfirmation();
    }

    const verify = () => {
        sendVerifyEmailCode({code, codeToken: temporaryToken ?? ""});
    }

    // If the temporary token is not obtained, then you should provide a view to get it again 
    if (!temporaryToken) {
        return (
            <Form>
                <FormGroup>
                    <Alert>To proceed, you must confirm your email address. Click below to resend the confirmation email.</Alert>
                </FormGroup>
                {isResendInError && <FormGroup><UncontrolledAlert color="danger">There was a problem resending the code. Please try again.</UncontrolledAlert></FormGroup>}
                <BusyButton
                    isBusy={isResendLoading}
                    onClick={resend}
                    label="Resend"
                />
            </Form>
        );
    }

    return (
        <Form>
            <FormGroup>
                <Label for="verifyCode">
                    Enter the confirmation code you received via email
                </Label>
                <Input
                    id="verifyCode"
                    name="verifyCode"
                    placeholder="enter the verification code"
                    type="text"
                    value={code}
                    onChange={e => setCode(e.target.value)}
                    valid={code.length >= 6}
                    invalid={code.length < 6 }
                />
                
            </FormGroup>
            {isVerifyInError && <FormGroup><UncontrolledAlert color="danger">There was a problem verifying the code. Please re-enter it and try again.</UncontrolledAlert></FormGroup>}
            <BusyButton
                disabled={code.length < 6}
                isBusy={isVerifyLoading}
                label="Verify"
                onClick={verify}
            />
            {isResendInError && <FormGroup><UncontrolledAlert color="danger">There was a problem resending the code. Please try again.</UncontrolledAlert></FormGroup>}
            <Button onClick={resend} color="link">I didn't receive a code</Button>
        </Form>
    );
}

export default VerifyEmail;

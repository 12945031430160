import { useEffect, useState } from 'react'
import { Alert, Container, Row, Col, Card, CardImg, CardBody, CardHeader, CardTitle, Input, CardText, Button, Modal, ModalHeader, ModalBody, Table, UncontrolledAlert } from 'reactstrap';
import { MyProductResponse, ProductStatus, useAllProductsQuery, useMyProductsQuery, useRequestDeliveryMutation, ProductResponse } from '../../redux/api/productApi';
import CardPayment from '../../components/Payment/CardPayment';
import { useSsoLoginMutation } from '../../redux/api/ayrshareApi';
import { SocialAuditProductName } from './socialAuditProductName';
import { useNavigate } from 'react-router-dom';
import { BusyButton } from '../Buttons/busyButton';

type SocialAuditStatusDetailsArgs = {
    product?: MyProductResponse;
}

const SocialAuditStatusDetails = ({ product }: SocialAuditStatusDetailsArgs) => {
    if (!product) {
        return (null);
    }

    const dateString = new Date(product.modifiedDateTimeUtc).toDateString();

    switch (product.status) {
        case ProductStatus.Active:
            return (<Alert>You enrolled in this product on {dateString}</Alert>);
        case ProductStatus.Pending:
            return (<Alert color="warning">Your submitted payment on {dateString} is still being processed</Alert>);
        case ProductStatus.Delivered:
            return (<Alert color="primary">Your last report was delivered on {dateString}.</Alert>);
        case ProductStatus.DeliveryRequested:
            return (<Alert color="warning">The report you requested on {dateString} is currently awaiting processing</Alert>);
        case ProductStatus.Error:
            return (<Alert color="danger">There was an error processing your last payment</Alert>);
        default:
            return (null);
    }
}

const SocialAuditStatus = ({ product }: SocialAuditStatusDetailsArgs) => {
    if (!product) {
        return (<td></td>);
    }

    switch (product.status) {
        case ProductStatus.Active:
            return (<td className="table-success">Enrolled</td>);
        case ProductStatus.Pending:
            return (<td className="table-warning">Payment Processing</td>);
        case ProductStatus.Delivered:
            return (<td className="table-primary">Delivered</td>);
        case ProductStatus.DeliveryRequested:
            return (<td className="table-info">In Progress</td>);
        case ProductStatus.Error:
            return (<td className="table-danger">Payment Error</td>);
        default:
            return (<td></td>);
    }
}

type SocialAuditManageActiveArgs = {
    products: MyProductResponse[]
}

const SocialAuditManageActive = ({ products }: SocialAuditManageActiveArgs) => {
    const [login, { isLoading: isSsoLoading, isSuccess: isSsoSuccess, isError: isSsoError, data: ssoUrl }] = useSsoLoginMutation();
    const [requestDelivery, { isLoading: isRequestDeliveryLoading, isError: isRequestDeliveryError}] = useRequestDeliveryMutation();

    const openSso = async () => {
        var loginResult = await login().unwrap();
        if (loginResult.value) window.open(loginResult.value, "_blank");
    }

    const requestReport = async () => {
        if (!window.confirm('Are you sure you want to request a report now? You will not be able to connect additional social media accounts once you submit it.'))
            return;
        //requestDelivery({sku: product.sku, id: product.paymentId});
    }

    return (
        <>
            <CardText>
                <dl>
                <dt>Step 1.</dt><dd>In order for us to perform our detailed analysis of your social media presence, you must
                first connect all your social media accounts. Click the Connect Accounts button below to open a new window where
                        you can connect your accounts.</dd>
                </dl>
            </CardText>
            {isSsoError && 
                <CardText><UncontrolledAlert color="danger">There was a problem opening the page to add your accounts. Please try again later.</UncontrolledAlert></CardText>}
            <BusyButton onClick={openSso} label="Connect Accounts" isBusy={isSsoLoading} />
            <hr></hr>
            <CardText >
                <dl>
                    <dt>Step 2.</dt><dd>When you are satisfied you have connected all your accounts, click the Request Report
                    button to submit your details for review</dd>
                </dl>
            </CardText>
            {isRequestDeliveryError &&
                <CardText><UncontrolledAlert color="danger">There was a problem trying to request your report. Please try again later.</UncontrolledAlert></CardText>}
            <BusyButton onClick={requestReport} label="Request Review" isBusy={isRequestDeliveryLoading} />
        </>);
}

const SocialAuditDetails = () => {
    const { data: allProducts, isError: allProductsInError } = useAllProductsQuery();
    const { data: myProducts, isError: myProductsInError } = useMyProductsQuery();
    const [productToBuy, setProductToBuy] = useState<ProductResponse | undefined>(undefined);
    const socialAuditGroup = allProducts?.productGroups.find(g => g.name === "Social Audit");
    const socialAuditMyGroup = myProducts?.productGroups.find(g => g.name === "Social Audit");
    const [selectedSkus, setSelectedSkus] = useState<string[] | undefined>();
    const navigate = useNavigate();

    const anyActive = socialAuditMyGroup && 
        socialAuditMyGroup.products.some(p => p.status === ProductStatus.Active && selectedSkus?.includes(p.sku));

    const onSelectionChanged = (sku: string) => {
        if (!selectedSkus) return;
        if (selectedSkus.includes(sku)) {
            setSelectedSkus(selectedSkus.filter(s => s !== sku));
        } else {
            setSelectedSkus([...selectedSkus, sku]);
        }
    }

    const getSelectedSkus = () => socialAuditMyGroup 
        ? socialAuditMyGroup.products.filter(p => p.status === ProductStatus.Active && selectedSkus?.includes(p.sku)).map(p => p.sku)
        : [];

    useEffect(() => {
        if (!socialAuditMyGroup || selectedSkus) return;

        setSelectedSkus(socialAuditMyGroup.products.filter(p => p.status === ProductStatus.Active).map(p => p.sku))
    }, [selectedSkus, socialAuditMyGroup])

    return (
        <>
            <Row>
                <Card>
                    <CardHeader tag="h3">Social Media Audit</CardHeader>
                    <CardBody>
                        <CardText>
                            Traditional analytics can only take you so far. Let us dive deeper into your content to see what's working &mdash; and what's not.
                        </CardText>
                        <CardText>
                            We don't just crunch numbers. We decode trends, dissect themes, and provide pathways to better understand your audience.
                                From sentiment tracking to engagement analysis, we leave no stone unturned in our journey to uncover actionable insights.
                        </CardText>
                        <h4>Which accounts do you want to Audit?</h4>
                        
                        {(allProductsInError || myProductsInError ) &&
                            <Row><UncontrolledAlert color="danger">There was an unexpected error obtaining information about this product</UncontrolledAlert></Row>}

                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Request Audit</th>
                                    <th>Product</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            {socialAuditGroup != null && socialAuditGroup.products.map(p => { 
                                const myProduct = socialAuditMyGroup?.products.find(pp => pp.sku === p.sku);
                                const canBuy = !myProduct || myProduct.status === ProductStatus.Delivered || myProduct.status === ProductStatus.Error;
                                const isEnrolled = myProduct && myProduct.status === ProductStatus.Active;
                                return (<tr>
                                    <td><Input 
                                            type="checkbox" 
                                            checked={isEnrolled && selectedSkus && selectedSkus.includes(p.sku)}
                                            disabled={!isEnrolled}
                                            onChange={e => onSelectionChanged(p.sku)}
                                        />
                                    </td>
                                    <td><SocialAuditProductName socialPlatformId={p.socialPlatformId} /></td>
                                    <SocialAuditStatus product={myProduct} />
                                    <td >
                                        <Button
                                            disabled={!canBuy}
                                            color="primary"
                                            onClick={() => {
                                                setProductToBuy(p);
                                            }}>Buy {myProduct ? "again" : ""} (${p.cost})
                                        </Button>
                                    </td>
                                </tr>);
                            })}
                            </tbody>
                        </Table>
                        <hr></hr>
                        <h4>Request an audit</h4>
                        <CardText>When you are satisfied that you have purchased Audits for all of your accounts, you can start the process
                        of requesting an audit by clicking the "Request Audit" button below.</CardText>
                        <Button disabled={!anyActive} color="primary" 
                            onClick={() => navigate("/products/socialAudit/connect", {
                                state: {skus: getSelectedSkus()}}) }>Request Audit</Button>
                    </CardBody>
                </Card>
            </Row>
            

            {productToBuy &&
                <Modal isOpen={true} toggle={() => setProductToBuy(undefined)}>
                    <ModalHeader>Pay now</ModalHeader>
                    <ModalBody>
                        <CardPayment
                            amount={productToBuy.cost}
                            skus={[productToBuy.sku]}
                            onCancel={() => { setProductToBuy(undefined) }}
                            onPaymentProcessed={() => { setProductToBuy(undefined) }} />
                    </ModalBody>
                </Modal>
                }
            
        </>);
}

export default SocialAuditDetails;
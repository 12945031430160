import { CSSProperties } from 'react';
import { Button, Spinner } from 'reactstrap';

type BusyButtonProps = {
    isBusy: boolean,
    disabled?: boolean,
    label: string,
    onClick: () => void
    type?: 'primary' | 'secondary',
    style?: CSSProperties | undefined
}

export const BusyButton = ({isBusy, disabled, label, onClick, type, style } : BusyButtonProps) => {

    return (<Button color="primary" onClick={onClick} className={`btn-busy btn btn-${type ?? 'primary'}`}
        disabled={disabled === true || isBusy} style={style ?? {}}>
        {isBusy ? <Spinner style={{ width: "20px", height: "20px" }} /> : label}
    </Button>);
}

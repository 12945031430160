import { api } from './api';

export type PaymentConfig = {
    id: string
}

export type CreateTransactionRequest = {
    skus: string[],
    savePaymentMethod: boolean,
    amount: number
}

export type CreateTransactionResponse = {
    id: string,
    paymentStatus: number,
    clientSecret: string
}

export type VerifyPaymentRequest = {
    id: string
}

export type VerifyPaymentResponse = {
    id: string,
    status: number,
}

export const enum PaymentStatus {
    Open = 0,
    Complete = 1,
    Canceled = 2,
    Error = 3,
}

export type PaymentMethod = {
    id: string,
    type: number,
    description: string,
    cardType?: number,
    expirationMonth: number,
    expirationYear: number
}

export type PaymentMethods = {
    paymentMethods: PaymentMethod[]
}

const paymentApi = api.injectEndpoints({
    endpoints: (build) => ({
        config: build.query<PaymentConfig, void>({
            query: () => ({
                url: 'payment/config',
                method: 'GET'
            })
        }),
        paymentMethods: build.query<PaymentMethods, void>({
            query: () => ({
                url: 'payment/Methods',
                method: 'GET'
            }),
            providesTags: ['Payment']
        }),
        deletePaymentMethod: build.mutation<void, string>({
            query: (id: string) => ({
                url: `payment/Methods/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Payment']
        }),
        createTransaction: build.mutation<CreateTransactionResponse, CreateTransactionRequest>({
            query: (request: CreateTransactionRequest) => ({
                url: 'payment/transactions',
                method: 'POST',
                body: request
            }),
            invalidatesTags: ['Product', 'Payment']
        }),
        verifyPayment: build.mutation<VerifyPaymentResponse, VerifyPaymentRequest>({
            query: (request: VerifyPaymentRequest) => ({
                url: `payment/PaymentVerifications`,
                method: 'POST',
                body: request
            }),
            invalidatesTags: ['Product', 'Payment']
        }),
    })
});

export const {
    useConfigQuery,
    useCreateTransactionMutation,
    usePaymentMethodsQuery,
    useVerifyPaymentMutation,
    useDeletePaymentMethodMutation
} = paymentApi;

export const {
    endpoints: {
        config,
        createTransaction,
        paymentMethods,
        verifyPayment,
        deletePaymentMethod
    },
} = paymentApi;